import React from "react";
import { Link } from "react-router-dom";

// 登入後畫面正上方的選單
const Menu = (props: any) => {
    // 當設定onChoice屬性時，選單中每一個連結點下後就會執行onChoice代表的function
    const { onChoice } = props;
    return (
        <div className="navbar-end">
            <Link
                className="navbar-item"
                to="/company"
                onClick={() => {
                    onChoice && onChoice();
                }}
            >
                公司
            </Link>
            <Link
                className="navbar-item"
                to="/sesRecords"
                onClick={() => {
                    onChoice && onChoice();
                }}
            >
                信件通知
            </Link>
            <Link
                className="navbar-item"
                to="/customerSupport"
                onClick={() => {
                    onChoice && onChoice();
                }}
            >
                客服紀錄
            </Link>
            <Link
                className="navbar-item"
                to="/report"
                onClick={() => {
                    onChoice && onChoice();
                }}
            >
                報告
            </Link>
        </div>
    );
};

export default Menu;
