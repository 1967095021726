import React, { useState } from "react";
import { connect } from "react-redux";
import { login as loginAction } from "../actions/loginAction";
import { loginApi } from "../api/adminApi";
import { ERROR_RESPONSE_TEXT } from "../constants/errorResponseText";
import { PageArea, Box, LoginPanel } from "../element/Block";
import { SubmitButton, ResetButton } from "../element/Button";
import { LoginLogo } from "element/Image";
import { ErrorMessage } from "../element/Message";
import { NormalInput, NormalPassword } from "element/Input";

// 登入頁面
const LoginPage = ({ currentAdmin, loginAction }: any) => {
    const [error, setErrors] = useState("");
    // 設定一個API call以帳號密碼進行登入
    const loginWithCallback = (event: any) => {
        event.preventDefault();
        const { account, password } = event.target;
        if (!account.value) {
            setErrors("帳號不得為空");
            return;
        }
        if (!password.value) {
            setErrors("密碼不得為空");
            return;
        }
        loginApi(account.value, password.value)
            .then((response) => {
                const {
                    data: { user },
                } = response;
                loginAction(user); // 成功的話用登入的admin資訊觸發redux的login action
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    return (
        <PageArea>
            {error && (
                <Box>
                    <ErrorMessage message={error} />
                </Box>
            )}
            <Box>
                <LoginPanel>
                    <LoginLogo />
                    <form action="post" onSubmit={loginWithCallback}>
                        <NormalInput label="帳號" name="account" />
                        <NormalPassword label="密碼" name="password" />
                        <ResetButton value="清除" />
                        <SubmitButton value="登入" />
                    </form>
                </LoginPanel>
            </Box>
        </PageArea>
    );
};

// 將redux中的state的currentAdmin屬性取出，並回傳一個包含currentAdmin屬性的物件
const mapStateToProps = (state: any) => {
    return {
        currentAdmin: state.currentAdmin,
    };
};

// 把上方回傳物件中的所有屬性，綁定成LoginPage的props，如此LoginPage的props就會有個名為currentAdmin的值
// 以及綁定redux的loginAction，如此LoginPage的props就會有名為loginAction的function
const LoginPageWithRedux = connect(mapStateToProps, {
    loginAction,
})(LoginPage);

// 輸出綁定過redux state跟action的LoginPage
export default LoginPageWithRedux;
