import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

// 搜尋條
export const SearchBar = (props: any) => {
    const { value, onChange, onSearch } = props;
    return (
        <form
            className="search-bar"
            onSubmit={(event) => {
                event.preventDefault();
                onSearch();
            }}
        >
            <div className="field has-addons">
                <p className="control">
                    <input
                        className="input is-small"
                        type="text"
                        value={value}
                        onChange={(event) => {
                            onChange(event.target.value);
                        }}
                    />
                </p>
                <p className="control">
                    <button className="button is-small is-success" type="submit">
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </p>
            </div>
        </form>
    );
};
