import axios from "axios";

// 登入
export const loginApi = (account: string, password: string) => {
    return axios.post("/auth/login", { account, password });
};

// 登出
export const logoutApi = () => {
    return axios.get("/auth/logout");
};

// 檢查是否登入
export const getLoginedAdminApi = () => {
    return axios.get("/auth/access");
};
