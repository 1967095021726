import React from "react";
import "./Block.css";

/*
    區域元素，這邊存放各種分割區域的元素，用以切割頁面區塊
*/

// 分割區塊
const block = (className: string) => {
    return (props: { children: any }) => {
        const { children } = props;
        return <div className={className}>{children}</div>;
    };
};
export const Box = block("box-la");
export const Grid = block("grid");
export const LoginPanel = block("login-panel");

// 頁面區塊
export const PageArea = (props: any) => {
    const { children } = props;
    return <section className="section">{children}</section>;
};
