/*
    登入人員身份設定
    但目前人員不多所以許多身份並沒有使用
*/

export const ADMIN = 1;
export const HUMAN_RESOURCE = 2;
export const ACCOUNTING = 3;
export const CUSTOMER_SERVICE = 4;

export const ADMIN_IDENTITY_TEXT: { [index: number]: string } = {
    [ADMIN]: "管理員",
    [HUMAN_RESOURCE]: "人資",
    [ACCOUNTING]: "會計",
    [CUSTOMER_SERVICE]: "客服",
};
