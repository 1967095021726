import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useParams, Link } from "react-router-dom";
import { allQueryParams } from "../helper/paramsPathMaker";
import "./Table.css";

/*
    表格元素
*/

// 表元素
export const Table = (props: { children: any }) => {
    const { children } = props;
    return (
        <div className="table-container">
            <table className="table is-striped is-hoverable is-fullwidth">{children}</table>
        </div>
    );
};

// 列元素
export const TableRow = (props: { children: any }) => {
    const { children } = props;
    return <tr className="table-row">{children}</tr>;
};

// 表格標題元素
export const TableCellHead = (props: { children: any | null; sortBy?: string; pathFor?: string; colSpan?: number }) => {
    const { children, sortBy, pathFor, colSpan } = props;
    const { search, sort = "", limit } = useParams() as any;
    const [sortField, sortType] = sort.split("-");
    const sortTypes = [undefined, "ASC", "DESC"];
    const sortIcons = [faSort, faCaretDown, faCaretUp];
    const currentIndex = sortTypes.indexOf(sortTypes.includes(sortType) ? sortType : "ASC");
    const [nextSortType, sortIcon] =
        sortField !== sortBy ? ["ASC", faSort] : [sortTypes[currentIndex + 1], sortIcons[currentIndex]];
    return (
        <th className="has-text-centered table-cell-head" colSpan={colSpan}>
            {children}
            {sortBy && (
                <Link to={pathFor + allQueryParams(search, nextSortType ? `${sortBy}-${nextSortType}` : "", limit, 1)}>
                    <FontAwesomeIcon icon={sortIcon} />
                </Link>
            )}
        </th>
    );
};

// 一般表格元素
export const TableCell = (props: { children: any; colSpan?: number }) => {
    const { children, colSpan } = props;
    return (
        <td className="table-cell" colSpan={colSpan}>
            {children}
        </td>
    );
};

// 省略表格過長內容元素
export const TableCellEllipsis = (props: { children: any }) => {
    const { children } = props;
    return (
        <td className="table-cell">
            <div className="ellipsis">{children}</div>
        </td>
    );
};

// 合併表格元素
export const TableCellColSpan = (props: { children: any; colSpan: number }) => {
    const { children, colSpan } = props;
    return (
        <td className="table-cell" colSpan={colSpan}>
            {children}
        </td>
    );
};
