import React from "react";
import "./Input.css";

/*
    輸入元素
*/

// 文字類型輸入元素
const textInput = (type: string) => {
    return (props: { label: string; name: string }) => {
        const { label, name } = props;
        return (
            <div className="text-input">
                <label className="text-input-label">{label}</label>
                <input className="text-input-field" type={type} name={name} />
            </div>
        );
    };
};
export const NormalInput = textInput("text");
export const NormalPassword = textInput("password");
