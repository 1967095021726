import React from "react";
import "./Message.css";

/*
    訊息元素
*/

// 純文字訊息
const message = (className: string) => {
    return (props: { message: string }) => {
        const { message } = props;
        return <span className={className}>（{message}）</span>;
    };
};
export const EmptyMessage = message("empty-message");
export const ErrorMessage = message("error-message");
