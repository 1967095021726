import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { getCompaniesApi } from "../api/companyApi";
import { PAGE_LIMIT, PAGE_LIMIT_OPTIONS } from "../constants/defaultSetting";
import { ERROR_RESPONSE_TEXT } from "../constants/errorResponseText";
import { OK, ERROR } from "constants/responseStatus";
import { PageArea, Box } from "../element/Block";
import { ViewIconButton, EditIconButton } from "../element/Button";
import { EmptyMessage, ErrorMessage } from "../element/Message";
import { Paginate } from "../element/Paginate";
import { SearchBar } from "../element/Search";
import { PageTitle, GridTitle } from "../element/Title";
import { allQueryParams } from "../helper/paramsPathMaker";
import { CompanyTable } from "../viewModel/Company";

// 公司一覽頁面
const CompaniesPage = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { search = "", sort = "", limit = PAGE_LIMIT, page = 1 } = useParams() as any; // 取出網址參數，若無則指定預設值
    const [searchText, setSearchText] = useState(search); // 搜尋條所輸入的關鍵字
    const [sortPair] = useState(sort); // 以哪個屬性順排或逆排
    const [currentLimit] = useState(PAGE_LIMIT_OPTIONS.includes(Number(limit)) ? Number(limit) : PAGE_LIMIT); // 每頁幾筆的參數，只能指定存在於PAGE_LIMIT_OPTIONS中的數值，否則變回預設值
    const [currentPage] = useState(Number(page)); // 目前第幾頁
    const [totalCount, setTotalCount] = useState(0); // 依目前的搜尋條件符合的公司總數
    const [companies, setCompanies] = useState([]); // 顯示在頁面上的公司資料
    const [error, setErrors] = useState(""); // 錯誤訊息
    // 設定一個API call用來取得符合搜尋條件的公司資料
    const getCompaniesWithCallback = () => {
        getCompaniesApi(searchText, sortPair, currentLimit, currentPage)
            .then((response) => {
                const {
                    data: { status, count, companies: companiesData, error },
                } = response;
                if (status === OK) {
                    setCompanies(companiesData); // 更新頁面上的公司資料
                    setTotalCount(count); // 更新公司總數
                    setErrors(""); // 清空錯誤訊息
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個function用來產出連結到公司詳細資訊的連結
    const linkToCompany = (company: any) => {
        const { id } = company;
        return (
            <Link
                to={{
                    pathname: `/company/id/${id}`, // 公司詳細資訊的連結
                    state: { goBackUrls: [pathname] },
                }}
                key="show"
            >
                <ViewIconButton key="show" title="檢視" />
            </Link>
        );
    };
    // 最初規劃想要可以編輯公司，但一直沒有下文，故遺留這個產出編輯公司的按鈕
    const linkToEditCompany = () => {
        return <EditIconButton key="edit" title="編輯" disabled={true} />;
    };
    useEffect(() => {
        getCompaniesWithCallback();
    }, []);
    return (
        <PageArea>
            <PageTitle>公司一覽</PageTitle>
            {error && (
                <Box>
                    <ErrorMessage message={error} />
                </Box>
            )}
            <Box>
                <div className="level mb-3">
                    <div className="level-left">
                        {/* 搜尋條 */}
                        <SearchBar
                            value={searchText}
                            onChange={(value: string) => {
                                setSearchText(value);
                            }}
                            onSearch={() => {
                                history.push("/company" + allQueryParams(searchText, sortPair));
                            }}
                        />
                    </div>
                </div>
                {totalCount > 0 ? (
                    <>
                        {/* 公司清單 */}
                        <CompanyTable companies={companies} actions={[linkToCompany, linkToEditCompany]} />
                        {/* 分頁資訊與控制連結 */}
                        <Paginate
                            count={totalCount}
                            limit={currentLimit}
                            pathFor={"/company" + allQueryParams(searchText, sortPair)}
                            currentPage={currentPage}
                            showInfo={true}
                        />
                    </>
                ) : (
                    <GridTitle>
                        <EmptyMessage message="查無紀錄" />
                    </GridTitle>
                )}
            </Box>
        </PageArea>
    );
};

export default CompaniesPage;
