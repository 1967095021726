import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getSesRecordsApi, resendEmailApi } from "../api/sesRecordApi";
import { PAGE_LIMIT, PAGE_LIMIT_OPTIONS } from "../constants/defaultSetting";
import { ERROR_RESPONSE_TEXT } from "../constants/errorResponseText";
import { OK, ERROR } from "constants/responseStatus";
import { PageArea, Box } from "../element/Block";
import { ResendIconButton } from "../element/Button";
import { EmptyMessage, ErrorMessage } from "../element/Message";
import { Paginate } from "../element/Paginate";
import { SearchBar } from "../element/Search";
import { PageTitle, GridTitle } from "../element/Title";
import { minutesFromTo } from "../helper/dateTimeFormatter";
import { allQueryParams } from "../helper/paramsPathMaker";
import { SesRecordTable } from "../viewModel/SesRecord";

// 信件通知一覽頁面
const SesRecordsPage = () => {
    const history = useHistory();
    const { search = "", sort = "", limit = PAGE_LIMIT, page = 1 } = useParams() as any; // 取出網址參數，若無則指定預設值
    const [searchText, setSearchText] = useState(search); // 搜尋條所輸入的關鍵字
    const [sortPair] = useState(sort); // 以哪個屬性順排或逆排
    const [currentLimit] = useState(PAGE_LIMIT_OPTIONS.includes(Number(limit)) ? Number(limit) : PAGE_LIMIT); // 每頁幾筆的參數，只能指定存在於PAGE_LIMIT_OPTIONS中的數值，否則變回預設值
    const [currentPage] = useState(Number(page)); // 目前第幾頁
    const [totalCount, setTotalCount] = useState(0); // 依目前的搜尋條件符合的信件紀錄總數
    const [sesRecords, setSesRecords] = useState([]); // 顯示在頁面上的信件紀錄資料
    const [error, setErrors] = useState("");
    const [timer, setTimer] = useState(new Date()); // 當下時間戮，用於檢查10分鐘內不得重送信件的判斷
    // 設定一個API call用來取得符合搜尋條件的信件紀錄資料
    const getSesRecordsWithCallback = () => {
        getSesRecordsApi(searchText, sortPair, currentLimit, currentPage)
            .then((response) => {
                const {
                    data: { status, count, sesRecords: sesRecordsData, error },
                } = response;
                if (status === OK) {
                    setSesRecords(sesRecordsData); // 更新頁面上的信件紀錄
                    setTotalCount(count); // 更新信件總數
                    setErrors(""); // 清空錯誤訊息
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定重送email的按鈕
    const ResendEmailButton = (sesRecord: any) => {
        const { id, lastSendTime } = sesRecord; // 取得信件紀錄的id跟最後寄送時間
        const [waitResponse, setWaitResponse] = useState(false); // 用來阻擋重送信件時response還沒回來又按一次的情況
        const minutesToAllowResend = 10; // 設定重送時長10分鐘
        const inMinutesLimit = minutesFromTo(lastSendTime, timer) < minutesToAllowResend; // 最後寄信時間到現在是否小於10分鐘
        // 設定一個API call重寄email
        const resendEmailApiWithCallBack = () => {
            setWaitResponse(true); // 設定等待response為true
            resendEmailApi(id)
                .then((response) => {
                    const {
                        data: { status, sesRecord: sesRecordData, error },
                    } = response;
                    if (status === OK) {
                        setSesRecords(
                            sesRecords.map((record: any) => {
                                return sesRecordData.id === record.id ? sesRecordData : record;
                            }) as any
                        ); // 成功的話找出現在信件紀錄中被重寄的那筆更新資料
                        setErrors("");
                    } else if (status === ERROR) {
                        setErrors(error);
                    }
                })
                .catch((error: any) => {
                    const {
                        response: { status },
                    } = error;
                    setErrors(ERROR_RESPONSE_TEXT[status]);
                })
                .finally(() => {
                    setWaitResponse(false); // 不論成功與否設定等待response為false
                });
        };
        // 回傳一個按鈕，在時間限制內或等待response時不能按，按下的時候會發送重寄信的API call
        return (
            <ResendIconButton
                key="resend"
                title={inMinutesLimit ? `${minutesToAllowResend}分鐘內無法重新寄送` : "重新寄送"}
                disabled={inMinutesLimit || waitResponse}
                onClick={resendEmailApiWithCallBack}
            />
        );
    };
    useEffect(() => {
        getSesRecordsWithCallback();
        const timerStart = setInterval(() => {
            setTimer(new Date());
        }, 1000); // 啟動計時器，每秒更新現在時間，用來比對每個寄信紀錄是否能寄信
        return () => {
            clearTimeout(timerStart); // 當離開這一頁的時候取消執行計時器
        };
    }, []);
    return (
        <PageArea>
            <PageTitle>信件通知一覽</PageTitle>
            {error && (
                <Box>
                    <ErrorMessage message={error} />
                </Box>
            )}
            <Box>
                <div className="level mb-3">
                    <div className="level-left">
                        <SearchBar
                            value={searchText}
                            onChange={(value: string) => {
                                setSearchText(value);
                            }}
                            onSearch={() => {
                                history.push("/sesRecords" + allQueryParams(searchText, sortPair));
                            }}
                        />
                    </div>
                </div>
                {totalCount > 0 ? (
                    <>
                        <SesRecordTable sesRecords={sesRecords} actions={[ResendEmailButton]} />
                        <Paginate
                            count={totalCount}
                            limit={currentLimit}
                            pathFor={"/sesRecords" + allQueryParams(searchText, sortPair)}
                            currentPage={currentPage}
                            showInfo={true}
                        />
                    </>
                ) : (
                    <GridTitle>
                        <EmptyMessage message="查無紀錄" />
                    </GridTitle>
                )}
            </Box>
        </PageArea>
    );
};

export default SesRecordsPage;
