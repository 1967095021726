/*
    http的錯誤代碼轉換成易懂的錯誤訊息
*/

export const ERROR_RESPONSE_TEXT: { [index: number]: string } = {
    400: "不正確的輸入", // Bad Request
    401: "帳號或密碼錯誤",
    404: "找不到XXX",
    500: "後端伺服器錯誤",
};
