import React from "react";
import { Link } from "react-router-dom";

// 登入後畫面左上方的logo
const Logo = () => {
    return (
        <Link className="navbar-item" to="/">
            <img src="/header-logo.png" alt="logo" />
        </Link>
    );
};

export default Logo;
