import axios from "axios";
import { allQueryParams } from "../helper/paramsPathMaker";

// 取得複數公司資料
export const getCompaniesApi = (search?: string, sort?: string, limit?: number, page?: number) => {
    return axios.get("/api/company" + allQueryParams(search, sort, limit, page));
};

// 取得單一公司資料
export const getCompanyApi = (id: number) => {
    return axios.get(`/api/company/${id}`);
};

// 變更公司訂閱
export const updateCompanyStatusApi = (subscriptionData: any) => {
    return axios.put("/api/company/changeSubscription", subscriptionData);
};

// 統計註冊公司數
export const countRegisteredCompaniesApi = () => {
    return axios.get("/api/company/countCompanies/byRegistered");
};

// 取得新註冊公司（3天內註冊）資料
export const getNewRegisteredCompaniesApi = () => {
    return axios.get("/api/company/countCompanies/getNewRegisters");
};

// 統計各種訂閱狀態公司數
export const countCompaniesEachStatusApi = () => {
    return axios.get("/api/company/countCompanies/byStatus");
};

// 統計月繳年繳公司數
export const countSubscribedCompaniesApi = () => {
    return axios.get("/api/company/countCompanies/bySubscriptions");
};

// 統計即將付費（30天內）公司數
export const countWillPayCompaniesApi = () => {
    return axios.get("/api/company/countCompanies/byWillPay");
};

// 取得即將付款公司（30天內）資料
export const getWillPayCompaniesApi = () => {
    return axios.get("/api/company/countCompanies/getWillPays");
};
