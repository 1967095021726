import React, { useState } from "react";
import { BANK_TRANSFER, PAYMENT_WAY_TEXT, PAYMENT_WAY_ARRAY } from "../constants/paymentWay";
import { MONTH, SUBSCRIPTION_PERIOD_FREQUENCY, SUBSCRIPTION_PERIOD_PRICE } from "../constants/subscriptionPeriod";
import { EmptyMessage } from "../element/Message";
import { Table, TableRow, TableCellHead, TableCell } from "../element/Table";
import { toDate, toDateTimeLocal } from "../helper/dateTimeFormatter";
import { numberToCurrency } from "../helper/numberFormatter";
import { InvoiceForm } from "./Invoice";
import "./Payment.css";

/*
    顯示付款紀錄相關資料的外觀
*/

// 付款紀錄列表
export const PaymentTable = (props: { payments: any[]; onAppendInvoice: Function }) => {
    const { payments, onAppendInvoice } = props;
    return (
        <Table>
            <tbody>
                <TableRow>
                    <TableCellHead>訂閱編號</TableCellHead>
                    <TableCellHead>付款狀態</TableCellHead>
                    <TableCellHead>付款方式</TableCellHead>
                    <TableCellHead>付款金額</TableCellHead>
                    <TableCellHead>繳/扣款日</TableCellHead>
                    <TableCellHead>Coupon</TableCellHead>
                </TableRow>
                {payments.map((payment: any, index) => {
                    const { subscriptionId, paymentWay, amount, paymentTime, invoice } = payment;
                    return (
                        <React.Fragment key={index}>
                            <TableRow>
                                <TableCell>{subscriptionId}</TableCell>
                                <TableCell>{paymentTime ? "已付款" : "未付款"}</TableCell>
                                <TableCell>{PAYMENT_WAY_TEXT[paymentWay]}</TableCell>
                                <TableCell>{numberToCurrency(amount)}</TableCell>
                                <TableCell>{toDate(paymentTime)}</TableCell>
                                <TableCell>{}</TableCell>
                            </TableRow>
                            {invoice ? (
                                <>
                                    <TableRow>
                                        <TableCellHead> </TableCellHead>
                                        <TableCellHead>發票號碼</TableCellHead>
                                        <TableCellHead>隨機碼</TableCellHead>
                                        <TableCellHead>開立日期</TableCellHead>
                                        <TableCellHead colSpan={2}>發票明細連結</TableCellHead>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell> </TableCell>
                                        <TableCell>{invoice.invoiceNo}</TableCell>
                                        <TableCell>{invoice.randomCode}</TableCell>
                                        <TableCell>{toDate(invoice.invoiceDate)}</TableCell>
                                        <TableCell colSpan={2}>
                                            {invoice.invoiceDetailUrl || <EmptyMessage message="無" />}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <InvoiceForm
                                            onSubmit={(invoiceData: any) => {
                                                const { id } = payment;
                                                onAppendInvoice({ ...invoiceData, id });
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </Table>
    );
};

// 付款紀錄表單
export const PaymentForm = (props: any) => {
    const {
        payment = {
            amount: SUBSCRIPTION_PERIOD_PRICE[MONTH],
            frequency: SUBSCRIPTION_PERIOD_FREQUENCY[MONTH],
            paymentWay: BANK_TRANSFER,
            paymentTime: toDateTimeLocal(new Date()),
            creditTime: toDateTimeLocal(new Date()),
        }, // 如果沒有從外部注入資料，則使用這邊設定的預設值作為表單的初始值
        onSubmit,
    } = props;
    const defaultPayment = {
        ...payment,
    };
    const [paymentData, setPaymentData] = useState(defaultPayment);
    const { amount, frequency, paymentWay, paymentTime, creditTime } = paymentData;
    return (
        <form
            className="mb-3"
            onSubmit={(event) => {
                event.preventDefault();
                const { id, amount, frequency, paymentWay, paymentTime, creditTime } = paymentData;
                onSubmit && onSubmit({ id, amount, frequency, paymentWay, paymentTime, creditTime });
            }}
        >
            <div className="field is-grouped is-justify-content-left">
                <div className="field is-horizontal mr-3">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">金額</label>
                    </div>
                    <div className="control">
                        <input
                            className="input is-small"
                            type="number"
                            value={amount}
                            min={0}
                            onChange={(event) => {
                                setPaymentData({
                                    ...paymentData,
                                    amount: Number(event.target.value),
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="field is-horizontal mr-3">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">天數</label>
                    </div>
                    <div className="control">
                        <input
                            className="input is-small"
                            type="number"
                            value={frequency}
                            min={0}
                            onChange={(event) => {
                                setPaymentData({
                                    ...paymentData,
                                    frequency: Number(event.target.value),
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="field is-horizontal mr-3">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">付款方式</label>
                    </div>
                    <div className="control">
                        <div className="select is-small">
                            <select
                                value={paymentWay}
                                onChange={(event) => {
                                    setPaymentData({
                                        ...paymentData,
                                        paymentWay: Number(event.target.value),
                                    });
                                }}
                            >
                                {PAYMENT_WAY_ARRAY.map((_, index) => (
                                    <option key={index} value={_}>
                                        {PAYMENT_WAY_TEXT[_]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal mr-3">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">付款時間</label>
                    </div>
                    <div className="control">
                        <input
                            className="input is-small"
                            type="datetime-local"
                            value={paymentTime}
                            onChange={(event) => {
                                setPaymentData({
                                    ...paymentData,
                                    paymentTime: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="field is-horizontal mr-3">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">入帳時間</label>
                    </div>
                    <div className="control">
                        <input
                            className="input is-small"
                            type="datetime-local"
                            value={creditTime}
                            onChange={(event) => {
                                setPaymentData({
                                    ...paymentData,
                                    creditTime: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="field is-horizontal mr-3">
                    <div className="control">
                        <input className="button is-success is-small" type="submit" value="新增付款" />
                    </div>
                </div>
            </div>
        </form>
    );
};
