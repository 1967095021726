import axios from "axios";
import { allQueryParams } from "../helper/paramsPathMaker";

// 取得所有信件紀錄
export const getSesRecordsApi = (search?: string, sort?: string, limit?: number, page?: number) => {
    return axios.get("/api/sesRecord" + allQueryParams(search, sort, limit, page));
};

// 重送信件
export const resendEmailApi = (id: number) => {
    return axios.get(`/api/sesRecord/resend/${id}`);
};
