import React, { useState, useEffect } from "react";
import {
    countRegisteredCompaniesApi,
    getNewRegisteredCompaniesApi,
    countCompaniesEachStatusApi,
    countSubscribedCompaniesApi,
    countWillPayCompaniesApi,
    getWillPayCompaniesApi,
} from "../api/companyApi";
import { countCustomerSupportsEachStatusApi } from "../api/customerSupportApi";
import { ERROR_RESPONSE_TEXT } from "../constants/errorResponseText";
import { OK, ERROR } from "../constants/responseStatus";
import { PageArea, Box } from "../element/Block";
import { FocusWrapper1 as FocusWrapper, FocusPoint1 as FocusPoint } from "../element/FocusPoint";
import { EmptyMessage, ErrorMessage } from "../element/Message";
import { PageTitle, BlockTitle, GridTitle } from "../element/Title";
import { numberToCurrency } from "../helper/numberFormatter";
import { CompanyNewRegisteredTable, CompanyWillPayTable } from "../viewModel/Company";

const ReportPage = () => {
    const [registeredCount, setRegisteredCount] = useState(0); // 計算總共幾間公司註冊
    const [newRegisteredCount, setNewRegisteredCount] = useState(0); // 計算新註冊（三天內）公司

    const [newRegisteredCompanies, setNewRegisteredCompanies] = useState([]); // 新註冊（三天內）公司資料

    const [freeActiveCount, setFreeActiveCount] = useState(0); // 計算免費使用公司數
    const [freeExpireCount, setFreeExpireCount] = useState(0); // 計算免費使用過期公司數
    const [activeCount, setActiveCount] = useState(0); // 計算付費使用公司數
    const [activeCancelCount, setActiveCancelCount] = useState(0); // 計算取消使用公司數
    const [inactivePaymentCount, setInactivePaymentCount] = useState(0); // 計算付款異常公司數
    const [suspendCount, setSuspendCount] = useState(0); // 計算中止使用公司數

    const [notYetCount, setNotYetCount] = useState(0); // 計算未處理客服紀錄數
    const [inProgressCount, setInProgressCount] = useState(0); // 計算處理中客服紀錄數
    const [pauseCount, setPauseCount] = useState(0); // 計算擱置客服紀錄數
    const [finishCount, setFinishCount] = useState(0); // 計算已結案客服紀錄數

    const [monthCount, setMonthCount] = useState(0); // 計算訂閱月繳公司數
    const [yearCount, setYearCount] = useState(0); // 計算訂閱年繳公司數

    const [monthWillPayCount, setMonthWillPayCount] = useState(0); // 計算30天內即將再付款月繳公司數
    const [yearWillPayCount, setYearWillPayCount] = useState(0); // 計算30天內即將再付款年繳公司數

    const [willPayCompanies, setWillPayCompanies] = useState([]); // 30天內即將再付款公司資料

    const [error, setErrors] = useState("");

    // 設定一個API call取得註冊公司總數、新註冊（三天內）公司數
    const countRegisteredCompaniesWithCallBack = () => {
        countRegisteredCompaniesApi()
            .then((response) => {
                const {
                    data: { status, registeredCount, newRegisteredCount, error },
                } = response;
                if (status === OK) {
                    setRegisteredCount(registeredCount); // 更新註冊公司總數
                    setNewRegisteredCount(newRegisteredCount); // 更新新註冊（三天內）公司總數
                    setErrors(""); // 清空錯誤訊息
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call取得新註冊（三天內）公司資料
    const getNewRegisteredCompaniesWithCallBack = () => {
        getNewRegisteredCompaniesApi()
            .then((response) => {
                const {
                    data: { status, companies, error },
                } = response;
                if (status === OK) {
                    setNewRegisteredCompanies(companies); // 更新新註冊公司資料
                    setErrors(""); // 清空錯誤訊息
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call取得各種訂閱狀態公司數
    const countCompaniesEachStatusWithCallBack = () => {
        countCompaniesEachStatusApi()
            .then((response) => {
                const {
                    data: {
                        status,
                        freeActiveCount,
                        freeExpireCount,
                        activeCount,
                        activeCancelCount,
                        inactivePaymentCount,
                        suspendCount,
                        error,
                    },
                } = response;
                if (status === OK) {
                    setFreeActiveCount(freeActiveCount); // 更新免費使用公司數
                    setFreeExpireCount(freeExpireCount); // 更新免費使用過期公司數
                    setActiveCount(activeCount); // 更新付費使用公司數
                    setActiveCancelCount(activeCancelCount); // 更新取消使用公司數
                    setInactivePaymentCount(inactivePaymentCount); // 更新付款異常公司數
                    setSuspendCount(suspendCount); // 更新中止使用公司數
                    setErrors(""); // 清空錯誤訊息
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call取得各種客服紀錄狀態數
    const countCustomerSupportsEachStatusWithCallBack = () => {
        countCustomerSupportsEachStatusApi()
            .then((response) => {
                const {
                    data: { status, notYetCount, inProgressCount, pauseCount, finishCount, error },
                } = response;
                if (status === OK) {
                    setNotYetCount(notYetCount); // 更新未處理客服紀錄數
                    setInProgressCount(inProgressCount); // 更新處理中客服紀錄數
                    setPauseCount(pauseCount); // 更新擱置客服紀錄數
                    setFinishCount(finishCount); // 更新已結案客服紀錄數
                    setErrors(""); // 清空錯誤訊息
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call取得月繳或年繳公司數
    const countSubscribedCompaniesWithCallBack = () => {
        countSubscribedCompaniesApi()
            .then((response) => {
                const {
                    data: { status, monthCount, yearCount, error },
                } = response;
                if (status === OK) {
                    setMonthCount(monthCount); // 更新月繳公司數
                    setYearCount(yearCount); // 更新年繳公司數
                    setErrors(""); // 清空錯誤訊息
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call取得30天內即將付款的月繳或年繳公司數
    const countWillPayCompaniesWithCallBack = () => {
        countWillPayCompaniesApi()
            .then((response) => {
                const {
                    data: { status, monthWillPayCount, yearWillPayCount, error },
                } = response;
                if (status === OK) {
                    setMonthWillPayCount(monthWillPayCount); // 更新30天內即將再付款的月繳公司數
                    setYearWillPayCount(yearWillPayCount); // 更新30天內即將再付款的年繳公司數
                    setErrors(""); // 清空錯誤訊息
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call取得30天內即將再付款的公司資料
    const getWillPayCompaniesWithCallBack = () => {
        getWillPayCompaniesApi()
            .then((response) => {
                const {
                    data: { status, companies, error },
                } = response;
                if (status === OK) {
                    setWillPayCompanies(companies); // 更新30天內即將再付款的公司資料
                    setErrors(""); // 清空錯誤訊息
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    useEffect(() => {
        countRegisteredCompaniesWithCallBack();
        getNewRegisteredCompaniesWithCallBack();
        countCompaniesEachStatusWithCallBack();
        countCustomerSupportsEachStatusWithCallBack();
        countSubscribedCompaniesWithCallBack();
        countWillPayCompaniesWithCallBack();
        getWillPayCompaniesWithCallBack();
    }, []);
    return (
        <PageArea>
            {error && (
                <Box>
                    <ErrorMessage message={error} />
                </Box>
            )}
            <PageTitle>報告</PageTitle>
            <BlockTitle>註冊統計</BlockTitle>
            <FocusWrapper className="is-info has-text-left">
                <FocusPoint labelText="總註冊數" point={numberToCurrency(registeredCount)} />
                <FocusPoint labelText="新註冊數" point={numberToCurrency(newRegisteredCount)} />
            </FocusWrapper>
            <BlockTitle>新註冊公司清單</BlockTitle>
            {newRegisteredCompanies.length > 0 ? (
                <CompanyNewRegisteredTable companies={newRegisteredCompanies} />
            ) : (
                <GridTitle>
                    <EmptyMessage message="查無紀錄" />
                </GridTitle>
            )}
            <BlockTitle>各訂閱狀態統計</BlockTitle>
            <FocusWrapper className="is-danger has-text-centered">
                <FocusPoint labelText="免費使用" point={numberToCurrency(freeActiveCount)} />
                <FocusPoint labelText="免費使用過期" point={numberToCurrency(freeExpireCount)} />
                <FocusPoint labelText="使用中" point={numberToCurrency(activeCount)} />
                <FocusPoint labelText="取消使用" point={numberToCurrency(activeCancelCount)} />
                <FocusPoint labelText="付款異常" point={numberToCurrency(inactivePaymentCount)} />
                <FocusPoint labelText="終止使用" point={numberToCurrency(suspendCount)} />
            </FocusWrapper>
            <BlockTitle>客服狀態統計</BlockTitle>
            <FocusWrapper className="is-warning has-text-right">
                <FocusPoint labelText="未處理" point={numberToCurrency(notYetCount)} />
                <FocusPoint labelText="處理中" point={numberToCurrency(inProgressCount)} />
                <FocusPoint labelText="擱置" point={numberToCurrency(pauseCount)} />
                <FocusPoint labelText="已結案" point={numberToCurrency(finishCount)} />
            </FocusWrapper>
            <BlockTitle>訂閱方案統計</BlockTitle>
            <FocusWrapper className="is-link has-text-centered">
                <FocusPoint labelText="訂年繳公司數" point={numberToCurrency(yearCount)} />
                <FocusPoint labelText="訂月繳公司數" point={numberToCurrency(monthCount)} />
            </FocusWrapper>
            <BlockTitle>續訂到期統計</BlockTitle>
            <FocusWrapper className="is-success has-text-left">
                <FocusPoint labelText="年繳" point={numberToCurrency(yearWillPayCount)} />
                <FocusPoint labelText="月繳" point={numberToCurrency(monthWillPayCount)} />
            </FocusWrapper>
            <BlockTitle>續訂到期清單</BlockTitle>
            {willPayCompanies.length > 0 ? (
                <CompanyWillPayTable companies={willPayCompanies} />
            ) : (
                <GridTitle>
                    <EmptyMessage message="查無紀錄" />
                </GridTitle>
            )}
        </PageArea>
    );
};

export default ReportPage;
