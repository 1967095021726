import React from "react";

/*
    按鈕群組元素
*/

// 一般按鈕群組
const buttonGroup = (size?: string, align?: string) => {
    return (props: any) => {
        const { children } = props;
        return <div className={`buttons has-addons ${size || ""} ${align || ""}`}>{children}</div>;
    };
};
export const SmallLeftButtonGroup = buttonGroup("are-small");
export const SmallCenterButtonGroup = buttonGroup("are-small", "is-centered");
export const SmallRightButtonGroup = buttonGroup("are-small", "is-right");
