/*
    客服紀錄問題類型設定
*/

export const ACCOUNT = 1;
export const DATA_SHOW = 2;
export const OPERATING = 3;
export const SUBSCRIPTION = 4;
export const PAYMENT = 5;
export const FEEDBACK = 6;
export const BUSINESS_EXPANSION = 7;
export const OTHERS = 9;

export const QUESTION_TYPE_TEXT: { [index: number]: string } = {
    [ACCOUNT]: "帳號資料",
    [DATA_SHOW]: "顯示資料",
    [OPERATING]: "功能操作",
    [SUBSCRIPTION]: "訂閱方案",
    [PAYMENT]: "付款帳務",
    [FEEDBACK]: "回饋建議",
    [BUSINESS_EXPANSION]: "業務拓展",
    [OTHERS]: "其他",
};

export const QUESTION_TYPE_ARRAY = [
    ACCOUNT,
    DATA_SHOW,
    OPERATING,
    SUBSCRIPTION,
    PAYMENT,
    FEEDBACK,
    BUSINESS_EXPANSION,
    OTHERS,
];
