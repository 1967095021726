import React, { useState } from "react";
import { toDateByDashed } from "helper/dateTimeFormatter";

/*
    顯示發票紀錄相關資料的外觀
*/

// 發票表單
export const InvoiceForm = (props: any) => {
    const {
        invoice = {
            invoiceNo: "",
            randomCode: "",
            invoiceDate: new Date(),
            invoiceDetailUrl: "",
        }, // 如果沒有從外部注入資料，則使用這邊設定的預設值作為表單的初始值
        onSubmit,
    } = props;
    const defaultInvoice = {
        ...invoice,
        invoiceDate: toDateByDashed(invoice.invoiceDate),
    };
    const [invoiceData, setInvoiceData] = useState(defaultInvoice);
    const { invoiceNo, randomCode, invoiceDate, invoiceDetailUrl } = invoiceData;
    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                const { id, invoiceNo, randomCode, invoiceDate, invoiceDetailUrl } = invoiceData;
                onSubmit && onSubmit({ id, invoiceNo, randomCode, invoiceDate, invoiceDetailUrl });
            }}
        >
            <div className="field is-grouped is-justify-content-right">
                <div className="field is-horizontal mr-3 mb-0">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">發票號碼</label>
                    </div>
                    <div className="control">
                        <input
                            className="input is-small"
                            type="text"
                            value={invoiceNo}
                            required
                            min={0}
                            onChange={(event) => {
                                setInvoiceData({
                                    ...invoiceData,
                                    invoiceNo: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="field is-horizontal mr-3 mb-0">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">隨機碼</label>
                    </div>
                    <div className="control">
                        <input
                            className="input is-small"
                            type="text"
                            value={randomCode}
                            required
                            min={0}
                            onChange={(event) => {
                                setInvoiceData({
                                    ...invoiceData,
                                    randomCode: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="field is-horizontal mr-3 mb-0">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">開立日期</label>
                    </div>
                    <div className="control">
                        <input
                            className="input is-small"
                            type="date"
                            value={invoiceDate}
                            min={0}
                            onChange={(event) => {
                                setInvoiceData({
                                    ...invoiceData,
                                    invoiceDate: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="field is-horizontal mr-3 mb-0">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">發票明細連結</label>
                    </div>
                    <div className="control">
                        <input
                            className="input is-small"
                            type="text"
                            value={invoiceDetailUrl}
                            min={0}
                            onChange={(event) => {
                                setInvoiceData({
                                    ...invoiceData,
                                    invoiceDetailUrl: event.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="field is-horizontal mr-3 mb-0">
                    <div className="control">
                        <input className="button is-success is-small" type="submit" value="補上發票" />
                    </div>
                </div>
            </div>
        </form>
    );
};
