import React, { useState } from "react";
import { ACTIVE, COMPANY_STATUS_TEXT, COMPANY_STATUS_TAG, COMPANY_STATUS_ARRAY } from "../constants/companyStatus";
import {
    BF,
    PRODUCT_TEXT,
    PRODUCT_ARRAY,
    BF_NORMAL_MODULE,
    BF_MODULE_TEXT,
    BF_MODULE_ARRAY,
} from "../constants/product";
import {
    MONTH,
    SUBSCRIPTION_PERIOD_TEXT,
    SUBSCRIPTION_PERIOD_PAID_ARRAY,
    SUBSCRIPTION_PERIOD_FREE_ARRAY,
    FREE,
} from "../constants/subscriptionPeriod";
import { EmptyMessage } from "../element/Message";
import { Table, TableRow, TableCellHead, TableCell } from "../element/Table";
import { toDate } from "../helper/dateTimeFormatter";
import "./Subscription.css";

/*
    顯示訂閱紀錄相關資料的外觀
*/

// 訂閱紀錄列表
export const SubscriptionTable = (props: { subscriptions: any[] }) => {
    const { subscriptions } = props;
    return (
        <Table>
            <tbody>
                <TableRow>
                    <TableCellHead>訂閱編號</TableCellHead>
                    <TableCellHead>訂閱狀態</TableCellHead>
                    <TableCellHead>訂閱日期</TableCellHead>
                    <TableCellHead>訂閱產品</TableCellHead>
                    <TableCellHead>產品版本</TableCellHead>
                    <TableCellHead>帳單週期</TableCellHead>
                    <TableCellHead>下個續訂日期</TableCellHead>
                    <TableCellHead>取消日期</TableCellHead>
                </TableRow>
                {subscriptions.map((subscription: any, index) => {
                    const { id, status, startTime, product, moduleSetting, period, nextPaymentDate, endTime } =
                        subscription;
                    return (
                        <TableRow key={index}>
                            <TableCell>{id}</TableCell>
                            <TableCell>{COMPANY_STATUS_TAG[status]}</TableCell>
                            <TableCell>{toDate(startTime)}</TableCell>
                            <TableCell>{PRODUCT_TEXT[product]}</TableCell>
                            <TableCell>{BF_MODULE_TEXT[moduleSetting]}</TableCell>
                            <TableCell>{SUBSCRIPTION_PERIOD_TEXT[period]}</TableCell>
                            <TableCell>
                                {nextPaymentDate ? toDate(nextPaymentDate) : <EmptyMessage message="無" />}
                            </TableCell>
                            <TableCell>{endTime ? toDate(endTime) : <EmptyMessage message="無" />}</TableCell>
                        </TableRow>
                    );
                })}
            </tbody>
        </Table>
    );
};

// 訂閱紀錄表單
export const SubscriptionForm = (props: any) => {
    const {
        subscription = {
            status: ACTIVE,
            period: MONTH,
            product: BF,
            moduleSetting: BF_NORMAL_MODULE,
        }, // 如果沒有從外部注入資料，則使用這邊設定的預設值作為表單的初始值
        onSubmit,
    } = props;
    const defaultSubscription = {
        ...subscription,
    };
    const [subscriptionData, setSubscriptionData] = useState(defaultSubscription);
    const { product, status, period, moduleSetting } = subscriptionData;
    const isActived = status === ACTIVE; // 設定當前訂閱紀錄是否為付費使用作為下面多個地方的判斷
    return (
        <form
            className="mb-3"
            onSubmit={(event) => {
                event.preventDefault();
                const { id, status, period, product, moduleSetting } = subscriptionData;
                onSubmit && onSubmit({ id, status, period, product, moduleSetting });
            }}
        >
            <div className="field is-grouped is-justify-content-left">
                <div className="field is-horizontal mr-3">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">產品</label>
                    </div>
                    <div className="control">
                        <div className="select is-small">
                            <select
                                value={product}
                                disabled
                                onChange={(event) => {
                                    setSubscriptionData({
                                        ...subscriptionData,
                                        product: Number(event.target.value),
                                    });
                                }}
                            >
                                {PRODUCT_ARRAY.map((_, index) => (
                                    <option key={index} value={_}>
                                        {PRODUCT_TEXT[_]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal mr-3">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">版本</label>
                    </div>
                    <div className="control">
                        <div className="select is-small">
                            <select
                                value={moduleSetting}
                                onChange={(event) => {
                                    setSubscriptionData({
                                        ...subscriptionData,
                                        moduleSetting: event.target.value,
                                    });
                                }}
                            >
                                {BF_MODULE_ARRAY.map((_, index) => (
                                    <option key={index} value={_}>
                                        {BF_MODULE_TEXT[_]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal mr-3">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">狀態</label>
                    </div>
                    <div className="control">
                        <div className="select is-small">
                            <select
                                value={status}
                                onChange={(event) => {
                                    const isPaidStatus = Number(event.target.value) === ACTIVE;
                                    setSubscriptionData({
                                        ...subscriptionData,
                                        status: Number(event.target.value),
                                        period: isPaidStatus ? MONTH : FREE,
                                    });
                                }}
                            >
                                {COMPANY_STATUS_ARRAY.map((_, index) => (
                                    <option key={index} value={_}>
                                        {COMPANY_STATUS_TEXT[_]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal mr-3">
                    <div className="field-label is-small mr-1 pt-2">
                        <label className="label">週期</label>
                    </div>
                    <div className="control">
                        <div className="select is-small">
                            <select
                                value={period}
                                disabled={!isActived}
                                onChange={(event) => {
                                    setSubscriptionData({
                                        ...subscriptionData,
                                        period: Number(event.target.value),
                                    });
                                }}
                            >
                                {(isActived ? SUBSCRIPTION_PERIOD_PAID_ARRAY : SUBSCRIPTION_PERIOD_FREE_ARRAY).map(
                                    (_, index) => (
                                        <option key={index} value={_}>
                                            {SUBSCRIPTION_PERIOD_TEXT[_]}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal mr-3">
                    <div className="control">
                        <input className="button is-success is-small" type="submit" value="變更訂閱" />
                    </div>
                </div>
            </div>
        </form>
    );
};
