import axios from "axios";
import { allQueryParams } from "../helper/paramsPathMaker";

// 取得所有客服紀錄
export const getCustomerSupportsApi = (search?: string, sort?: string, limit?: number, page?: number) => {
    return axios.get("/api/customerSupport" + allQueryParams(search, sort, limit, page));
};

// 取得單一客服紀錄
export const getCustomerSupportApi = (id: number) => {
    return axios.get(`/api/customerSupport/${id}`);
};

// 新增客服紀錄
export const createCustomerSupportApi = (customerSupportData: any) => {
    return axios.post("/api/customerSupport", customerSupportData);
};

// 更新客服紀錄
export const updateCustomerSupportApi = (customerSupportData: any) => {
    return axios.put("/api/customerSupport", customerSupportData);
};

// 統計各項客服紀錄狀態數
export const countCustomerSupportsEachStatusApi = () => {
    return axios.get("/api/customerSupport/countCustomerSupports/byStatus");
};

// 切換客服紀錄是否能編輯
export const switchActivedCustomerSupportApi = (customerSupportData: any) => {
    return axios.put("/api/customerSupport/switchActived", customerSupportData);
};
