import React from "react";

/*
    焦點元素，用於報告頁面
    最初規劃三種樣式在報告頁面，後來選用第一種
*/

// 焦點區塊1
export const FocusWrapper1 = (props: any) => {
    const { children } = props;
    return <nav className="level mb-6">{children}</nav>;
};

// 焦點1
export const FocusPoint1 = (props: any) => {
    const { labelText, point } = props;
    return (
        <div className="level-item has-text-centered">
            <div>
                <p className="heading">{labelText}</p>
                <p className="title">{point}</p>
            </div>
        </div>
    );
};

// 焦點區塊2
export const FocusWrapper2 = (props: any) => {
    const { children, className } = props;
    return <section className={`hero is-small ${className ? className : ""}`}>{children}</section>;
};

// 焦點2
export const FocusPoint2 = (props: any) => {
    const { labelText, point } = props;
    return (
        <div className="hero-body">
            <p className="title">{point}</p>
            <p className="subtitle">{labelText}</p>
        </div>
    );
};

// 焦點區塊3
export const FocusWrapper3 = (props: any) => {
    const { children } = props;
    return <section className="field is-grouped is-grouped-multiline">{children}</section>;
};

// 焦點3
export const FocusPoint3 = (props: any) => {
    const { labelText, point } = props;
    return (
        <div className="control">
            <div className="tags has-addons are-large">
                <span className="tag">{labelText}</span>
                <span className="tag is-success">{point}</span>
            </div>
        </div>
    );
};
