import axios from "axios";

// 取得某一客服紀錄的所有回覆（未使用）
export const getCustomerSupportRepliesApi = (customerSupportId: number) => {
    return axios.get(`/api/customerSupportReply/customerSupportId/${customerSupportId}`);
};

// 取得單一客服回覆紀錄（未使用）
export const getCustomerSupportReplyApi = (id: number) => {
    return axios.get(`/api/customerSupportReply/${id}`);
};

// 新增客服回覆紀錄
export const createCustomerSupportReplyApi = (customerSupportReplyData: any) => {
    return axios.post("/api/customerSupportReply", customerSupportReplyData);
};

// 更新客服回覆紀錄
export const updateCustomerSupportReplyApi = (customerSupportReplyData: any) => {
    return axios.put("/api/customerSupportReply", customerSupportReplyData);
};
