import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faEye, faEnvelope, IconDefinition } from "@fortawesome/free-solid-svg-icons";

/*
    按鈕元素
*/

// 一般按鈕
const button = (className: string, type?: "button" | "submit" | "reset" | undefined) => {
    return (props: { onClick?: Function; value: string }) => {
        const { onClick, value } = props;
        return (
            <button
                className={className}
                type={type}
                onClick={() => {
                    onClick && onClick();
                }}
            >
                {value}
            </button>
        );
    };
};
export const Button = button("button is-small is-link", "button");
export const SubmitButton = button("button is-small is-link", "submit");
export const ResetButton = button("button is-small", "reset");
export const LogoutButton = button("button is-small is-info is-outlined is-rounded", "button");

// 圖形按鈕
const iconButton = (iconStyle: IconDefinition, bulmaColor: string) => {
    return (props: { onClick?: Function; title?: string; disabled?: boolean }) => {
        const { onClick, title, disabled } = props;
        return (
            <button
                className={`button is-small ${bulmaColor}`}
                title={title}
                disabled={disabled}
                onClick={() => {
                    onClick && onClick();
                }}
            >
                <FontAwesomeIcon icon={iconStyle} />
            </button>
        );
    };
};
export const ViewIconButton = iconButton(faEye, "is-info");
export const EditIconButton = iconButton(faPencilAlt, "is-success");
export const ResendIconButton = iconButton(faEnvelope, "is-link");
