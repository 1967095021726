import React from "react";
import { Link } from "react-router-dom";

/*
    連結元素
*/

// 返回連結
export const BackLink = (props: any) => {
    const { children, to } = props;
    return (
        <Link className="button is-info is-outlined is-rounded" to={to}>
            {children}
        </Link>
    );
};
