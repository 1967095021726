const initialState = null;

// 設定存放在redux state中的登入admin資訊，名為currentAdmin
const currentAdmin = (state = initialState, action: any) => {
    // 視action中傳入的type來觸發currentAdmin最後被賦予什麼值
    switch (action.type) {
        case "LOGIN": {
            const { currentAdmin } = action.payload;
            return currentAdmin;
        }
        case "LOGOUT": {
            return null;
        }
        default: {
            return state;
        }
    }
};

export default currentAdmin;
