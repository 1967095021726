import React from "react";
import "./Image.css";

/*
    圖片元素
*/

// 登入頁面Logo
export const LoginLogo = () => {
    return <img className="login-logo" src="/login-logo.png" alt="logo" />;
};
