/*
    用於存放生成url path的function
*/

// 生成搜尋、排序、每頁筆數、第幾頁的url path
export const allQueryParams = (search?: string, sort?: string, limit?: number, page?: number) => {
    return (
        (search ? `/search/${search}` : "") +
        (sort ? `/sort/${sort}` : "") +
        (limit && page ? `/limit/${limit}/page/${page}` : "")
    );
};

// 生成每頁筆數、第幾頁的url path
export const paginationParams = (limit?: number, page?: number) => {
    return limit && page ? `/limit/${limit}/page/${page}` : "";
};
