import React, { useState } from "react";
import { connect } from "react-redux";
import Logo from "./Logo";
import Menu from "./Menu";
import LoginInfo from "./LoginInfo";

//登入後顯示在畫面最上方的header
const Header = ({ currentAdmin }: any) => {
    // burgerOpen用於控制在行動裝置顯示時，選單濃縮在右上方的漢堡條開關
    const [burgerOpen, setBurgerOpen] = useState(false);
    // 如果登入的情況下currentAdmin會存有登入資訊，則顯示header，否則不顯示
    return currentAdmin ? (
        <nav
            className="navbar is-fixed-top"
            role="navigation"
            aria-label="main navigation"
            onMouseLeave={() => {
                setBurgerOpen(false); // 當游標移開header時自動關閉漢堡條選單
            }}
        >
            <div className="navbar-brand">
                {/* 就是logo */}
                <Logo />
                {/* 漢堡條按鈕，畫面寬不滿1024px才會顯示 */}
                <button
                    className="navbar-burger"
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbarBasicExample"
                    onClick={() => {
                        setBurgerOpen(!burgerOpen);
                    }}
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </button>
            </div>
            {/* 以下區塊為畫面寬低於1024px時會被濃縮在漢堡條選單中的內容 */}
            <div id="navbarBasicExample" className={`navbar-menu ${burgerOpen ? "is-active" : ""}`}>
                {/* 頁面選單 */}
                <Menu
                    onChoice={() => {
                        setBurgerOpen(false); // 選單被選擇過後關閉漢堡條選單
                    }}
                />
                {/* 登入資訊 */}
                <LoginInfo />
            </div>
        </nav>
    ) : null;
};

// 將redux中的state的currentAdmin屬性取出，並回傳一個包含currentAdmin屬性的物件
const mapStateToProps = (state: any) => {
    return {
        currentAdmin: state.currentAdmin,
    };
};
// 把上方回傳物件中的所有屬性，綁定成Header的props，如此Header的props就會有個名為currentAdmin的值
const HeaderWithRedux = connect(mapStateToProps)(Header);
// 輸出綁定過redux state的Header
export default HeaderWithRedux;
