import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faBriefcase, faCheck, faRedo, faUser } from "@fortawesome/free-solid-svg-icons";
import { getCompaniesApi } from "../api/companyApi";
import { CONTACT_WAY_TEXT, CONTACT_WAY_ARRAY } from "../constants/contactWay";
import {
    CUSTOMER_SUPPORT_STATUS_TEXT,
    CUSTOMER_SUPPORT_STATUS_TAG,
    CUSTOMER_SUPPORT_STATUS_ARRAY,
} from "../constants/customerSupportStatus";
import { ERROR_RESPONSE_TEXT } from "../constants/errorResponseText";
import { QUESTION_TYPE_TEXT, QUESTION_TYPE_ARRAY } from "../constants/questionType";
import { OK, ERROR } from "../constants/responseStatus";
import { ErrorMessage } from "../element/Message";
import { Table, TableRow, TableCellHead, TableCell, TableCellEllipsis } from "../element/Table";
import { toDate, toDateByDashed, toDateTime } from "../helper/dateTimeFormatter";
import { slashNToBr } from "../helper/htmlConvertter";
import "./CustomerSupport.css";

/*
    顯示客服紀錄相關資料的外觀
*/

// 客服紀錄列表
export const CustomerSupportTable = (props: { customerSupports: any[]; actions?: any[] }) => {
    const { actions, customerSupports } = props;
    return (
        <Table>
            <tbody>
                <TableRow>
                    <TableCellHead>服務編號</TableCellHead>
                    <TableCellHead>公司名稱</TableCellHead>
                    <TableCellHead sortBy="contactName" pathFor="/customerSupport">
                        聯絡人名稱
                    </TableCellHead>
                    <TableCellHead>客服名稱</TableCellHead>
                    <TableCellHead>申請日期</TableCellHead>
                    <TableCellHead>問題</TableCellHead>
                    <TableCellHead>狀態</TableCellHead>
                    <TableCellHead>最後更新</TableCellHead>
                    {actions && <TableCellHead> </TableCellHead>}
                </TableRow>
                {customerSupports.map((customerSupport: any, index) => {
                    const {
                        supportNo,
                        contactName,
                        contactTime,
                        question,
                        status,
                        updatedAt,
                        admin: { name: adminName },
                        company: { name: companyName },
                    } = customerSupport;
                    return (
                        <TableRow key={index}>
                            <TableCell>{supportNo}</TableCell>
                            <TableCell>{companyName}</TableCell>
                            <TableCell>{contactName}</TableCell>
                            <TableCell>{adminName}</TableCell>
                            <TableCell>{toDate(contactTime)}</TableCell>
                            <TableCellEllipsis>{question}</TableCellEllipsis>
                            <TableCell>{CUSTOMER_SUPPORT_STATUS_TAG[status]}</TableCell>
                            <TableCell>{toDate(updatedAt)}</TableCell>
                            {actions && (
                                <TableCell>{actions && actions.map((action) => action(customerSupport))}</TableCell>
                            )}
                        </TableRow>
                    );
                })}
            </tbody>
        </Table>
    );
};

// 檢視客服紀錄資訊
export const CustomerSupportCard = (props: { customerSupport: any; onSwitchActived?: Function; actions?: any[] }) => {
    const {
        customerSupport: {
            supportNo,
            question,
            questionType,
            contactName,
            contactTime,
            status,
            isActived, // 客服問題是否可以編輯的判斷值
            updatedAt,
            admin: { name: adminName },
            company: { name: companyName },
        },
        onSwitchActived,
        actions,
    } = props;
    // activeMenuOn用來控制，是否允許編輯選單的開關
    const [activeMenuOn, setActiveMenuOn] = useState(false);
    return (
        <div className="card">
            <header className="card-header">
                <p className="card-header-title">問題</p>
                <div className={`dropdown is-right ${activeMenuOn ? "is-active" : ""}`}>
                    <div className="dropdown-trigger">
                        {/* 開啟編輯選單按鈕 */}
                        <button
                            className="card-header-icon"
                            aria-label="more options"
                            onClick={() => {
                                // 每次按做相反的切換
                                setActiveMenuOn(!activeMenuOn);
                            }}
                        >
                            <FontAwesomeIcon icon={activeMenuOn ? faAngleUp : faAngleDown} size="lg" />
                        </button>
                    </div>
                    {/* 是否允許編輯的選單 */}
                    <div
                        className="dropdown-menu"
                        id="dropdown-menu"
                        role="menu"
                        onMouseLeave={() => {
                            // 當滑鼠移開選單後關閉選單
                            setActiveMenuOn(false);
                        }}
                    >
                        <div className="dropdown-content">
                            {/* 禁止編輯鈕 */}
                            <button
                                className="button is-fullwidth is-black is-inverted"
                                disabled={!isActived}
                                onClick={() => {
                                    // 如果有外部注入的onSwitchActived function就執行
                                    onSwitchActived && onSwitchActived(false);
                                    // 關閉選單
                                    setActiveMenuOn(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                                標示為已結案
                            </button>
                            {/* 允許編輯鈕 */}
                            <button
                                className="button is-fullwidth is-black is-inverted"
                                disabled={isActived}
                                onClick={() => {
                                    // 如果有外部注入的onSwitchActived function就執行
                                    onSwitchActived && onSwitchActived(true);
                                    // 關閉選單
                                    setActiveMenuOn(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faRedo} />
                                回復為處理中
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <div className="card-content">
                <div className="content is-medium">
                    <strong>{supportNo}</strong> {CUSTOMER_SUPPORT_STATUS_TAG[status]}
                    <FontAwesomeIcon icon={faUser} /> 建立者：
                    {adminName}
                </div>
                <div className="content">
                    <label className="customer-support-label">
                        <FontAwesomeIcon icon={faBriefcase} />
                        公司
                    </label>
                    {companyName}
                    <label className="customer-support-label">
                        <FontAwesomeIcon icon={faUser} />
                        聯絡人
                    </label>
                    {contactName}
                </div>
                <div className="content">
                    <label className="customer-support-label">問題分類</label>
                    {QUESTION_TYPE_TEXT[questionType]}
                </div>
                <div className="content">
                    <label className="customer-support-label">建立日期</label>
                    {toDate(contactTime)}
                </div>
                <div className="content">
                    <label className="customer-support-label">問題內容</label>
                </div>
                <div className="content">
                    <div
                        className="customer-support-question"
                        dangerouslySetInnerHTML={{ __html: slashNToBr(question) }}
                    />
                </div>
                <div className="content">
                    <label className="customer-support-label">最後更新</label>
                    {toDateTime(updatedAt)}
                </div>
            </div>
            {actions && <footer className="card-footer">{actions.map((action) => action)}</footer>}
        </div>
    );
};

// 客服紀錄表單
export const CustomerSupportForm = (props: any) => {
    const { state = {} } = useLocation();
    const { byCompanyId } = state as any;
    const defaultOptionValue = "";
    const {
        currentAdmin: { id: currentAdminId }, // 從redux的state中取出現在登入admin的id
        customerSupport = {
            companyId: byCompanyId || defaultOptionValue,
            contactName: "",
            contactWay: defaultOptionValue,
            contactTime: new Date(),
            questionType: defaultOptionValue,
            status: defaultOptionValue,
            question: "",
        }, // 如果沒有從外部注入資料，則使用這邊設定的預設值作為表單的初始值
        onSubmit,
        actions,
    } = props;
    const defaultSupport = {
        ...customerSupport,
        contactTime: toDateByDashed(customerSupport.contactTime),
    };
    const [customerSupportData, setCustomerSupportData] = useState(defaultSupport);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [error, setErrors] = useState("");
    const { companyId, contactName, contactWay, contactTime, questionType, status, question, adminId } =
        customerSupportData;
    const noAuthor = !adminId; // 設定是否沒有作者的判斷值，沒有的話為新增狀態
    const isAuthor = currentAdminId === adminId; // 設定當前登入的admin是否為這個客服紀錄的原始記錄人
    // 用api function call取得資料庫中的所有公司，作為客服紀錄是哪間公司的問題的選項
    const getCompaniesWithCallback = () => {
        getCompaniesApi()
            .then((response) => {
                const {
                    data: { status, companies, error },
                } = response;
                if (status === OK) {
                    setCompanyOptions(
                        companies.map((company: any) => {
                            const { id, name } = company;
                            return { id, name };
                        })
                    );
                    setErrors("");
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    const defaultOption = <option value={defaultOptionValue}>--請選擇--</option>;
    useEffect(() => {
        getCompaniesWithCallback();
    }, []);
    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                const { id, contactName, contactWay, contactTime, questionType, status, question } =
                    customerSupportData;
                onSubmit &&
                    onSubmit({
                        id,
                        companyId,
                        contactName,
                        contactWay,
                        contactTime,
                        question,
                        ...(noAuthor || isAuthor
                            ? {
                                  questionType,
                                  status,
                              }
                            : {}),
                    });
            }}
        >
            <div className="card">
                <div className="card-content">
                    {error && (
                        <div className="content">
                            <ErrorMessage message={error} />
                        </div>
                    )}
                    <div className="content">
                        <div className="columns mb-0">
                            <div className="column">
                                <label className="label">公司名稱</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select
                                            value={companyId}
                                            required
                                            disabled={customerSupport.companyId}
                                            onChange={(event) => {
                                                setCustomerSupportData({
                                                    ...customerSupportData,
                                                    companyId: Number(event.target.value),
                                                });
                                            }}
                                        >
                                            {defaultOption}
                                            {companyOptions.map(({ id, name }, index) => (
                                                <option key={index} value={id}>
                                                    {name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <label className="label">聯絡人名稱</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        type="text"
                                        value={contactName}
                                        required
                                        maxLength={255}
                                        onChange={(event) => {
                                            setCustomerSupportData({
                                                ...customerSupportData,
                                                contactName: event.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="columns mb-0">
                            <div className="column">
                                <label className="label">訊息來源</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select
                                            value={contactWay}
                                            required
                                            onChange={(event) => {
                                                setCustomerSupportData({
                                                    ...customerSupportData,
                                                    contactWay: Number(event.target.value),
                                                });
                                            }}
                                        >
                                            {defaultOption}
                                            {CONTACT_WAY_ARRAY.map((_, index) => (
                                                <option key={index} value={_}>
                                                    {CONTACT_WAY_TEXT[_]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <label className="label">建立日期</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        type="date"
                                        value={contactTime}
                                        required
                                        onChange={(event) => {
                                            setCustomerSupportData({
                                                ...customerSupportData,
                                                contactTime: event.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="columns mb-0">
                            <div className="column">
                                <label className="label">問題分類</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select
                                            value={questionType}
                                            required
                                            disabled={!noAuthor && !isAuthor}
                                            onChange={(event) => {
                                                setCustomerSupportData({
                                                    ...customerSupportData,
                                                    questionType: Number(event.target.value),
                                                });
                                            }}
                                        >
                                            {defaultOption}
                                            {QUESTION_TYPE_ARRAY.map((_, index) => (
                                                <option key={index} value={_}>
                                                    {QUESTION_TYPE_TEXT[_]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <label className="label">處理狀態</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select
                                            value={status}
                                            required
                                            disabled={!noAuthor && !isAuthor}
                                            onChange={(event) => {
                                                setCustomerSupportData({
                                                    ...customerSupportData,
                                                    status: Number(event.target.value),
                                                });
                                            }}
                                        >
                                            {defaultOption}
                                            {CUSTOMER_SUPPORT_STATUS_ARRAY.map((_, index) => (
                                                <option key={index} value={_}>
                                                    {CUSTOMER_SUPPORT_STATUS_TEXT[_]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns mb-0">
                            <div className="column">
                                <label className="label">問題內容</label>
                                <div className="control">
                                    <textarea
                                        className="textarea"
                                        value={question}
                                        required
                                        disabled={!noAuthor && !isAuthor}
                                        maxLength={1024}
                                        onChange={(event) => {
                                            setCustomerSupportData({
                                                ...customerSupportData,
                                                question: event.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="columns mb-0">
                            <div className="column">
                                <div className="level">
                                    <div className="level-left">
                                        <input
                                            className="button is-small"
                                            type="reset"
                                            value="取消"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setCustomerSupportData(defaultSupport);
                                            }}
                                        />
                                    </div>
                                    <div className="level-right">
                                        <input className="button is-small is-success" type="submit" value="儲存" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {actions && <footer className="card-footer">{actions.map((action: any) => action)}</footer>}
            </div>
        </form>
    );
};
// 將redux中的state的currentAdmin屬性取出，並回傳一個包含currentAdmin屬性的物件
const mapStateToProps = (state: any) => {
    const { currentAdmin } = state;
    return {
        currentAdmin,
    };
};
// 把上方回傳物件中的所有屬性，綁定成CustomerSupportForm的props，如此CustomerSupportForm的props就會有個名為currentAdmin的值
export const CustomerSupportFormWithRedux = connect(mapStateToProps)(CustomerSupportForm);
