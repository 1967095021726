/*
    公司訂閱狀態設定
*/

import React from "react";

export const FREE_ACTIVE = 1;
export const FREE_EXPIRE = 2;
export const ACTIVE = 10;
export const ACTIVE_CANCEL = 11;
export const INACTIVE_PAYMENT = 12;
export const SUSPEND = 20;

export const COMPANY_STATUS_TEXT: { [index: number]: string } = {
    [FREE_ACTIVE]: "免費（使用中）",
    [FREE_EXPIRE]: "免費（已過期）",
    [ACTIVE]: "使用中",
    [ACTIVE_CANCEL]: "取消使用",
    [INACTIVE_PAYMENT]: "付款異常",
    [SUSPEND]: "中止使用",
};

// 直接使用寫好的component當回傳值方便統一維護
export const COMPANY_STATUS_TAG: { [index: number]: any } = {
    [FREE_ACTIVE]: <span className="tag is-link">試用中</span>,
    [FREE_EXPIRE]: <span className="tag is-danger">試用過期</span>,
    [ACTIVE]: <span className="tag is-success">使用中</span>,
    [ACTIVE_CANCEL]: <span className="tag is-warning">即將取消</span>,
    [INACTIVE_PAYMENT]: <span className="tag is-danger">付款異常</span>,
    [SUSPEND]: <span className="tag is-black">中止使用</span>,
};

export const COMPANY_STATUS_ARRAY = [FREE_ACTIVE, FREE_EXPIRE, ACTIVE, ACTIVE_CANCEL, INACTIVE_PAYMENT, SUSPEND];
