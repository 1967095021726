import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import {
    getCustomerSupportApi,
    updateCustomerSupportApi,
    switchActivedCustomerSupportApi,
} from "../api/customerSupportApi";
import { createCustomerSupportReplyApi, updateCustomerSupportReplyApi } from "../api/customerSupportReplyApi";
import { ERROR_RESPONSE_TEXT } from "../constants/errorResponseText";
import { OK, ERROR } from "../constants/responseStatus";
import { PageArea, Box, Grid } from "../element/Block";
import { SmallCenterButtonGroup } from "../element/ButtonGroup";
import { BackLink } from "../element/Link";
import { EmptyMessage, ErrorMessage } from "../element/Message";
import { PageTitle, GridTitle } from "../element/Title";
import { CustomerSupportFormWithRedux as CustomerSupportForm, CustomerSupportCard } from "../viewModel/CustomerSupport";
import { CustomerSupportReplyForm, CustomerSupportReplyCard } from "../viewModel/CustomerSupportReply";

// 客服紀錄頁面
const CustomerSupportPage = (props: any) => {
    const {
        currentAdmin: { id: currentAdminId },
    } = props;
    const { id } = useParams() as any;
    const { path, url } = useRouteMatch();
    const { state = {} } = useLocation();
    const history = useHistory();
    const { goBackUrls = ["/customerSupport"] } = state as any; // 取得從哪個path進到此頁面，若無，則認定為從/customerSupport進入
    const newGoBackUrls = [...goBackUrls]; // 準備用於記錄再下一頁可以返回上一頁的url array，避免覆蓋到本來的goBackUrls
    const lastGoBackUrl = newGoBackUrls.pop(); // 取出最後一次返回上頁的path
    const [customerSupport, setCustomerSupport] = useState({
        id: null,
        isActived: false,
        adminId: null,
        admin: { name: "" },
        company: { name: "" },
    }); // 當前檢視的客服紀錄資料，因為有編輯模式的關係需有預設值，但不會用到
    const [customerSupportReplies, setCustomerSupportReplies] = useState([]); // 該客服紀錄的回覆資料
    const [isNewReplyOpen, setNewReplyOpen] = useState(false); // 是否開啟回覆表單
    const [editReplyIndex, setEditReplyIndex] = useState(-1); // 目前第幾個客服回覆紀錄為開啟狀態
    const [error, setErrors] = useState("");
    const { isActived } = customerSupport;
    // 設定一個API call用來取得該筆客服紀錄
    const getSupportWithCallback = () => {
        getCustomerSupportApi(id)
            .then((response) => {
                const {
                    data: { status, customerSupport, error },
                } = response;
                if (status === OK) {
                    if (customerSupport) {
                        const { customerSupportReplies, ...customerSupportData } = customerSupport;
                        setCustomerSupport(customerSupportData); // 更新客服紀錄
                        setCustomerSupportReplies(customerSupportReplies); // 更新該客服紀錄的回覆紀錄
                    }
                    setErrors(""); // 清空錯誤訊息
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call用來更新客服紀錄
    const updateSupportWithCallback = (customerSupportData: any) => {
        updateCustomerSupportApi(customerSupportData)
            .then((response) => {
                const {
                    data: { status, customerSupport, error },
                } = response;
                if (status === OK) {
                    setCustomerSupport(customerSupport); // 更新克服紀錄
                    history.push({ pathname: url, state: { goBackUrls } }); // 導回檢視模式
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call切換客服紀錄是否能夠編輯
    const switchSupportWithCallback = (isActived: boolean) => {
        switchActivedCustomerSupportApi({ id, isActived })
            .then((response) => {
                const {
                    data: { status, customerSupport, error },
                } = response;
                if (status === OK) {
                    setCustomerSupport(customerSupport); // 更新客服紀錄
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call新增一筆回覆紀錄
    const createReplyWithCallback = (customerSupportReplyData: any) => {
        createCustomerSupportReplyApi(customerSupportReplyData)
            .then((response) => {
                const {
                    data: { status, customerSupportReply, error },
                } = response;
                if (status === OK) {
                    setCustomerSupportReplies([...customerSupportReplies, customerSupportReply] as any); // 把最新的回覆紀錄塞回所有回覆紀錄的第一筆
                    setNewReplyOpen(false); // 關閉顯示回覆客服紀錄表單
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call更新回復客服紀錄
    const updateReplyWithCallback = (customerSupportReplyData: any) => {
        updateCustomerSupportReplyApi(customerSupportReplyData)
            .then((response) => {
                const {
                    data: { status, customerSupportReply: customerSupportReplyData, error },
                } = response;
                if (status === OK) {
                    setCustomerSupportReplies(
                        customerSupportReplies.map((customerSupportReply: any) => {
                            return customerSupportReply.id === customerSupportReplyData.id
                                ? { ...customerSupportReply, ...customerSupportReplyData }
                                : customerSupportReply;
                        }) as any
                    ); // 找出回覆客服紀錄中被更新的那筆紀錄然後更新
                    setEditReplyIndex(-1); // 設定當前編輯的回覆紀錄的index為-1，則所有回覆紀錄都會是檢視狀態
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個function用來產出打開回覆紀錄開關的連結
    const openReplyForm = (
        <Link
            key="reply"
            className="card-footer-item"
            to="#"
            onClick={() => {
                setNewReplyOpen(!isNewReplyOpen);
                setEditReplyIndex(-1);
            }}
        >
            {isNewReplyOpen ? "取消回覆" : "回覆"}
        </Link>
    );
    // 設定一個function用來產出切換回檢視客服紀錄的連結
    const linkToShow = (
        <Link key="show" className="card-footer-item" to={{ pathname: url, state: { goBackUrls } }}>
            檢視
        </Link>
    );
    // 設定一個function用來產出切換到編輯客服紀錄的連結
    const linkToEdit = (
        <Link key="edit" className="card-footer-item" to={{ pathname: `${url}/edit`, state: { goBackUrls } }}>
            編輯
        </Link>
    );
    // 設定一個function用來產出顯示克服紀錄的卡片
    const showCustomerSupport = (
        <CustomerSupportCard
            customerSupport={customerSupport}
            onSwitchActived={switchSupportWithCallback}
            actions={[openReplyForm, isActived ? linkToEdit : linkToShow]}
        />
    );
    // 設定一個function用來產出編輯客服紀錄的表單
    const editCustomerSupport = (
        <CustomerSupportForm
            customerSupport={customerSupport}
            onSubmit={(customerSupportData: any) => {
                updateSupportWithCallback(customerSupportData);
            }}
            actions={[linkToShow]}
        />
    );
    useEffect(() => {
        getSupportWithCallback();
    }, []);
    return (
        <PageArea>
            <PageTitle>客服紀錄</PageTitle>
            {error && (
                <Box>
                    <ErrorMessage message={error} />
                </Box>
            )}
            {customerSupport ? (
                <>
                    <Box>
                        <Switch>
                            <Route exact path={path}>
                                {showCustomerSupport}
                            </Route>
                            <Route path={`${path}/edit`}>{isActived ? editCustomerSupport : showCustomerSupport}</Route>
                        </Switch>
                    </Box>
                    {isNewReplyOpen && (
                        <Box>
                            <CustomerSupportReplyForm
                                onSubmit={(customerSupportReplyData: any) => {
                                    const { id: customerSupportId } = customerSupport;
                                    createReplyWithCallback({
                                        ...customerSupportReplyData,
                                        customerSupportId,
                                    });
                                }}
                            />
                        </Box>
                    )}
                    {customerSupportReplies.map((customerSupportReply, index) => {
                        const { adminId } = customerSupportReply;
                        return (
                            <Box key={index}>
                                {editReplyIndex === index && currentAdminId === adminId ? (
                                    <CustomerSupportReplyForm
                                        customerSupportReply={customerSupportReply}
                                        actions={
                                            currentAdminId === adminId
                                                ? [
                                                      <Link
                                                          key="reply"
                                                          className="card-footer-item"
                                                          to="#"
                                                          onClick={() => {
                                                              setEditReplyIndex(-1);
                                                          }}
                                                      >
                                                          取消編輯
                                                      </Link>,
                                                  ]
                                                : undefined
                                        }
                                        onSubmit={(customerSupportReplyData: any) => {
                                            updateReplyWithCallback(customerSupportReplyData);
                                        }}
                                    />
                                ) : (
                                    <CustomerSupportReplyCard
                                        customerSupportReply={customerSupportReply}
                                        actions={
                                            currentAdminId === adminId
                                                ? [
                                                      <Link
                                                          key="reply"
                                                          className="card-footer-item"
                                                          to="#"
                                                          onClick={() => {
                                                              setEditReplyIndex(index);
                                                              setNewReplyOpen(false);
                                                          }}
                                                      >
                                                          編輯
                                                      </Link>,
                                                  ]
                                                : undefined
                                        }
                                    />
                                )}
                            </Box>
                        );
                    })}
                </>
            ) : (
                <Box>
                    <Grid>
                        <GridTitle>
                            <EmptyMessage message="查無資料" />
                        </GridTitle>
                    </Grid>
                </Box>
            )}
            <SmallCenterButtonGroup>
                <BackLink to={{ pathname: lastGoBackUrl, state: { goBackUrls: newGoBackUrls } }}>回客服紀錄</BackLink>
            </SmallCenterButtonGroup>
        </PageArea>
    );
};

// 將redux中的state的currentAdmin屬性取出，並回傳一個包含currentAdmin屬性的物件
const mapStateToProps = (state: any) => {
    const { currentAdmin } = state;
    return {
        currentAdmin,
    };
};

// 把上方回傳物件中的所有屬性，綁定成CustomerSupportPage的props，如此CustomerSupportPage的props就會有個名為currentAdmin的值
const CustomerSupportPageWithRedux = connect(mapStateToProps)(CustomerSupportPage);

export default CustomerSupportPageWithRedux;
