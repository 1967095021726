/*
    付款方式的種類設定
*/

export const ATM = 1;
export const CREDIT_CARD = 2;
export const BANK_TRANSFER = 3;

export const PAYMENT_WAY_TEXT: { [index: number]: string } = {
    [ATM]: "ATM",
    [CREDIT_CARD]: "信用卡",
    [BANK_TRANSFER]: "銀行轉帳",
};

export const PAYMENT_WAY_ARRAY = [ATM, CREDIT_CARD, BANK_TRANSFER];
