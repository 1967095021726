import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { createCustomerSupportApi } from "../api/customerSupportApi";
import { ERROR_RESPONSE_TEXT } from "../constants/errorResponseText";
import { OK, ERROR } from "../constants/responseStatus";
import { PageArea, Box } from "../element/Block";
import { SmallCenterButtonGroup } from "../element/ButtonGroup";
import { BackLink } from "../element/Link";
import { ErrorMessage } from "../element/Message";
import { PageTitle } from "../element/Title";
import { CustomerSupportFormWithRedux as CustomerSupportForm } from "../viewModel/CustomerSupport";

// 新增客服紀錄頁面
const CustomerSupportNewPage = () => {
    const { state = {} } = useLocation();
    const history = useHistory();
    const { goBackUrls = ["/customerSupport"] } = state as any; // 取得從哪個path進到此頁面，若無，則認定為從/customerSupport進入
    const newGoBackUrls = [...goBackUrls]; // 準備用於記錄再下一頁可以返回上一頁的url array，避免覆蓋到本來的goBackUrls
    const lastGoBackUrl = newGoBackUrls.pop(); // 取出最後一次返回上頁的path
    const [error, setErrors] = useState("");
    // 設定一個API call用來新增客服紀錄
    const createSupportWithCallback = (customerSupportData: any) => {
        createCustomerSupportApi(customerSupportData)
            .then((response) => {
                const {
                    data: { status, error },
                } = response;
                if (status === OK) {
                    history.push({ pathname: lastGoBackUrl, state: { goBackUrls: newGoBackUrls } }); // 導回從哪一頁連過來的path
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    return (
        <PageArea>
            <PageTitle>新增客服紀錄</PageTitle>
            {error && (
                <Box>
                    <ErrorMessage message={error} />
                </Box>
            )}
            <Box>
                <CustomerSupportForm
                    onSubmit={(customerSupportData: any) => {
                        createSupportWithCallback(customerSupportData);
                    }}
                />
            </Box>
            {/* 返回上一頁的連結 */}
            <SmallCenterButtonGroup>
                <BackLink to={{ pathname: lastGoBackUrl, state: { goBackUrls: newGoBackUrls } }}>回客服紀錄</BackLink>
            </SmallCenterButtonGroup>
        </PageArea>
    );
};

export default CustomerSupportNewPage;
