import React from "react";

/*
    標題元素
*/

// 頁面標題
export const PageTitle = (props: any) => {
    const { children } = props;
    return <h1 className="title is-4">{children}</h1>;
};

// 區塊標題
export const BlockTitle = (props: any) => {
    const { children } = props;
    return <h2 className="title is-5">{children}</h2>;
};

// 小區塊標題
export const GridTitle = (props: { children: any }) => {
    const { children } = props;
    return <h3 className="title is-6">{children}</h3>;
};
