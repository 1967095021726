/*
    存放redux的action function
*/

export const login = (currentAdmin: any) => ({
    type: "LOGIN",
    payload: {
        currentAdmin,
    },
});

export const logout = () => ({
    type: "LOGOUT",
    payload: {
        currentAdmin: null,
    },
});
