/*
    客服紀錄的聯絡方式設定
*/

export const EMAIL = 1;
export const PHONE = 2;
export const OFFICAL_WEB_FORM = 3;
export const PRIVATE_MESSAGE = 4;
export const COMMUNICATION_SOFTWARE = 5;
export const INTERNAL_RETURN = 6;
export const OTHERS = 9;

export const CONTACT_WAY_TEXT: { [index: number]: string } = {
    [EMAIL]: "客服信箱",
    [PHONE]: "客服電話",
    [OFFICAL_WEB_FORM]: "官網表單",
    [PRIVATE_MESSAGE]: "粉專私訊",
    [COMMUNICATION_SOFTWARE]: "通訊軟體",
    [INTERNAL_RETURN]: "內部回報",
    [OTHERS]: "其他",
};

export const CONTACT_WAY_ARRAY = [
    EMAIL,
    PHONE,
    OFFICAL_WEB_FORM,
    PRIVATE_MESSAGE,
    COMMUNICATION_SOFTWARE,
    INTERNAL_RETURN,
    OTHERS,
];
