/*
    付款方案設定
*/

export const FREE = 1;
export const MONTH = 2;
export const YEAR = 3;

export const SUBSCRIPTION_PERIOD_TEXT: { [key: number]: string } = {
    [FREE]: "免費制",
    [MONTH]: "月繳制",
    [YEAR]: "年繳制",
};

export const SUBSCRIPTION_PERIOD_FREQUENCY: { [key: number]: number } = {
    [FREE]: 0,
    [MONTH]: 30,
    [YEAR]: 365,
};

export const SUBSCRIPTION_PERIOD_PRICE: { [key: number]: number } = {
    [FREE]: 0,
    [MONTH]: 750,
    [YEAR]: 8100,
};

export const SUBSCRIPTION_PERIOD_ARRAY = [FREE, MONTH, YEAR];
export const SUBSCRIPTION_PERIOD_PAID_ARRAY = [MONTH, YEAR];
export const SUBSCRIPTION_PERIOD_FREE_ARRAY = [FREE];
