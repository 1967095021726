/*
    產品設定
*/

export const BF = 1; // bothfree
export const BF_NORMAL_MODULE = "11110"; // bothfree一般版設定
export const BF_LITE_MODULE = "00001"; // bothfree lite版設定

export const PRODUCT_TEXT: { [index: number]: string } = {
    [BF]: "Both Free",
};

export const BF_MODULE_TEXT: { [index: string]: string } = {
    [BF_NORMAL_MODULE]: "Both Free一般",
    [BF_LITE_MODULE]: "Both Free Lite",
};

export const PRODUCT_ARRAY = [BF];

export const BF_MODULE_ARRAY = [BF_NORMAL_MODULE, BF_LITE_MODULE];
