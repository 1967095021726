/*
    公司VIP種類設定
    當初規劃後沒有使用也不知道是否要照金銀鑽石分級
*/

import React from "react";

export const NORMAL_USERS = 1;
export const FIRST_USERS = 2;
export const VIP = 3;
export const VVIP = 4;

export const COMPANY_VIP_TEXT: { [index: number]: string } = {
    [NORMAL_USERS]: "一般使用者",
    [FIRST_USERS]: "首批使用者",
    [VIP]: "VIP",
    [VVIP]: "VVIP",
};

// 直接使用寫好的component當回傳值方便統一維護
export const COMPANY_VIP_TAG: { [index: number]: any } = {
    [NORMAL_USERS]: <span className="tag is-light is-link">一般使用者</span>,
    [FIRST_USERS]: <span className="tag is-light is-info">首批使用者</span>,
    [VIP]: <span className="tag is-light is-success">VIP</span>,
    [VVIP]: <span className="tag is-light is-warning">VVIP</span>,
};
