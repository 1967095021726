import React, { useState, useEffect } from "react";
import { Link, Switch, Route, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { getCompanyApi, updateCompanyStatusApi } from "../api/companyApi";
import { updateNextPaymentDateApi } from "../api/subscriptionApi";
import { appendInvoiceApi } from "../api/paymentApi";
import { ERROR_RESPONSE_TEXT } from "../constants/errorResponseText";
import { OK, ERROR } from "constants/responseStatus";
import { PageArea, Box, Grid } from "../element/Block";
import { ViewIconButton, EditIconButton } from "../element/Button";
import { SmallLeftButtonGroup, SmallCenterButtonGroup, SmallRightButtonGroup } from "../element/ButtonGroup";
import { BackLink } from "../element/Link";
import { EmptyMessage, ErrorMessage } from "../element/Message";
import { PageTitle, BlockTitle, GridTitle } from "../element/Title";
import { FutureManagerDetail, CompanyDetail } from "../viewModel/Company";
import { CustomerSupportTable } from "../viewModel/CustomerSupport";
import { PaymentTable, PaymentForm } from "../viewModel/Payment";
import { SubscriptionTable, SubscriptionForm } from "../viewModel/Subscription";
import { ACTIVE } from "constants/companyStatus";

// 公司詳細資訊頁面
const CompanyPage = () => {
    const { id } = useParams() as any;
    const { path, url } = useRouteMatch();
    const { state = {}, pathname } = useLocation();
    const { goBackUrls = ["/company"] } = state as any; // 取得從哪個path進到此頁面，若無，則認定為從/company進入
    const newGoBackUrls = [...goBackUrls]; // 準備用於記錄再下一頁可以返回上一頁的url array，避免覆蓋到本來的goBackUrls
    const lastGoBackUrl = newGoBackUrls.pop(); // 取出最後一次返回上頁的path
    const [company, setCompany] = useState(null); // 當前檢視的公司資料
    const [subscriptions, setSubscriptions] = useState([{ id: null, status: null }]); // 當前公司的訂閱紀錄
    const [payments, setPayments] = useState([]); // 當前公司的付款紀錄
    const [customerSupports, setCustomerSupports] = useState([]); // 當前公司的客服紀錄
    const [error, setErrors] = useState("");
    const newestSubscription = subscriptions[0]; // 最新的訂閱紀錄
    const { id: newestSubscriptionId, status: newestSubscriptionStatus } = newestSubscription; // 取出並宣告最新的訂閱紀錄id跟狀態
    // 設定一個API call用來取得檢視公司的資料
    const getCompanyWithCallback = () => {
        getCompanyApi(id)
            .then((response) => {
                const {
                    data: { status, company, error },
                } = response;
                if (status === OK) {
                    // 有找到公司的話
                    if (company) {
                        const { subscriptions, payments, customerSupports, ...companyData } = company;
                        setCompany(companyData); // 更新公司資料
                        setSubscriptions(subscriptions); // 更新公司訂閱紀錄
                        setPayments(payments); // 更新公司付款紀錄
                        setCustomerSupports(customerSupports); // 更新公司客服紀錄
                    }
                    setErrors("");
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call變更公司訂閱狀態
    const updateCompanyStatusWithCallback = (subscriptionData: any) => {
        updateCompanyStatusApi(subscriptionData)
            .then((response) => {
                const {
                    data: { status, company, oldSubscription, newSubscription, error },
                } = response;
                if (status === OK) {
                    setCompany(company); // 更新公司資料
                    setSubscriptions([
                        newSubscription,
                        ...subscriptions.map((subscription) => {
                            const { id: subscriptionId } = subscription;
                            const { id: oldSubscriptionId } = oldSubscription;
                            return subscriptionId === oldSubscriptionId ? oldSubscription : subscription;
                        }),
                    ] as any); // 把最新的訂閱紀錄塞回所有訂閱紀錄的第一筆，並更新上一筆訂閱紀錄的資料
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call更新下個付款日
    const updateNextPaymentDateWithCallback = (paymentData: any) => {
        updateNextPaymentDateApi(paymentData)
            .then((response) => {
                const {
                    data: { status, subscription: paidSubscription, payment, error },
                } = response;
                if (status === OK) {
                    setSubscriptions(
                        subscriptions.map((subscription) => {
                            const { id: paidSubscriptionId } = paidSubscription;
                            const { id: subscriptionId } = subscription;
                            return paidSubscriptionId === subscriptionId ? paidSubscription : subscription;
                        }) as any
                    ); // 找到這次是依據哪個訂閱紀錄付款，更新這個訂閱紀錄
                    setPayments([payment, ...payments] as any); // 把新的付款紀錄塞回所有付款紀錄的第一筆
                } else if (status === ERROR) {
                    setErrors(error);
                    window.scrollTo(0, 0);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個API call附上發票資訊
    const appendInvoiceWithCallback = (invoiceData: any) => {
        appendInvoiceApi(invoiceData)
            .then((response) => {
                const {
                    data: { status, payment: paymentWithAppend, invoice, error },
                } = response;
                if (status === OK) {
                    setPayments(
                        payments.map((payment: any) => {
                            const { id: paymentId } = payment;
                            const { id: paymentIdWithAppend } = paymentWithAppend;
                            return paymentId === paymentIdWithAppend ? { ...payment, invoice } : payment;
                        }) as any
                    ); // 找出依據哪個付款紀錄開的發票，更新這個付款紀錄
                } else if (status === ERROR) {
                    setErrors(error);
                    window.scrollTo(0, 0);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個function用來產出連結到檢視客服紀錄的連結
    const linkToCustomerSupport = (customerSupport: any) => {
        const { id } = customerSupport;
        return (
            <Link
                to={{
                    pathname: `/customerSupport/id/${id}`, // 檢視客服紀錄的連結
                    state: {
                        goBackUrls: [...goBackUrls, pathname], // 把返回上一頁的堆疊加入當前的path
                    },
                }}
                key="show"
            >
                <ViewIconButton key="show" title="檢視" />
            </Link>
        );
    };
    // 設定一個function用來產出連結到編輯客服紀錄的連結
    const linkToEditCustomerSupport = (customerSupport: any) => {
        const { id, isActived } = customerSupport;
        return isActived ? (
            <Link
                to={{
                    pathname: `/customerSupport/id/${id}/edit`, // 編輯客服紀錄的連結
                    state: {
                        goBackUrls: [...goBackUrls, pathname], // 把返回上一頁的堆疊加入當前的path
                    },
                }}
                key="edit"
            >
                <EditIconButton key="edit" title="編輯" />
            </Link>
        ) : (
            <EditIconButton key="edit" title="編輯" disabled />
        );
    };
    useEffect(() => {
        getCompanyWithCallback();
    }, []);
    return (
        <PageArea>
            <PageTitle>公司詳細資訊</PageTitle>
            {error && (
                <Box>
                    <ErrorMessage message={error} />
                </Box>
            )}
            {company ? (
                <>
                    <Box>
                        <BlockTitle>帳號資訊</BlockTitle>
                        <FutureManagerDetail company={company} />
                    </Box>
                    <Box>
                        <BlockTitle>公司詳細資訊</BlockTitle>
                        <CompanyDetail company={company} />
                    </Box>
                    <Box>
                        <div className="level mb-3">
                            <div className="level-left">
                                <Switch>
                                    <Route exact path={path}></Route>
                                    <Route path={`${path}/subscriptions`}></Route>
                                    <Route path={`${path}/payments`}></Route>
                                    <Route path={`${path}/customerSupports`}>
                                        <SmallLeftButtonGroup>
                                            <Link
                                                className="button is-link"
                                                to={{
                                                    pathname: "/customerSupport/new",
                                                    state: { goBackUrls: [...goBackUrls, pathname], byCompanyId: id },
                                                }}
                                            >
                                                新增
                                            </Link>
                                        </SmallLeftButtonGroup>
                                    </Route>
                                </Switch>
                            </div>
                            {/* 切換三種紀錄的按鈕組 */}
                            <div className="level-right">
                                <SmallRightButtonGroup>
                                    <Link
                                        className={`button ${`${url}/subscriptions` === pathname ? "is-info" : ""}`}
                                        to={{ pathname: `${url}/subscriptions`, state: { goBackUrls } }}
                                    >
                                        訂閱紀錄
                                    </Link>
                                    <Link
                                        className={`button ${`${url}/payments` === pathname ? "is-info" : ""}`}
                                        to={{ pathname: `${url}/payments`, state: { goBackUrls } }}
                                    >
                                        付款紀錄
                                    </Link>
                                    <Link
                                        className={`button ${`${url}/customerSupports` === pathname ? "is-info" : ""}`}
                                        to={{ pathname: `${url}/customerSupports`, state: { goBackUrls } }}
                                    >
                                        客服紀錄
                                    </Link>
                                </SmallRightButtonGroup>
                            </div>
                        </div>
                        <Switch>
                            <Route exact path={path}></Route>
                            {/* 該公司訂閱紀錄清單 */}
                            <Route path={`${path}/subscriptions`}>
                                <SubscriptionForm
                                    onSubmit={(subscriptionData: any) => {
                                        const { id } = company;
                                        updateCompanyStatusWithCallback({
                                            ...subscriptionData,
                                            id,
                                        });
                                    }}
                                />
                                {subscriptions.length !== 0 ? (
                                    <SubscriptionTable subscriptions={subscriptions} />
                                ) : (
                                    <GridTitle>
                                        <EmptyMessage message="查無紀錄" />
                                    </GridTitle>
                                )}
                            </Route>
                            {/* 該公司付款紀錄清單 */}
                            <Route path={`${path}/payments`}>
                                {newestSubscriptionStatus === ACTIVE && (
                                    <PaymentForm
                                        onSubmit={(paymentData: any) => {
                                            updateNextPaymentDateWithCallback({
                                                ...paymentData,
                                                id: newestSubscriptionId,
                                            });
                                        }}
                                    />
                                )}
                                {payments.length !== 0 ? (
                                    <PaymentTable
                                        payments={payments}
                                        onAppendInvoice={(invoiceData: any) => {
                                            appendInvoiceWithCallback(invoiceData);
                                        }}
                                    />
                                ) : (
                                    <GridTitle>
                                        <EmptyMessage message="查無紀錄" />
                                    </GridTitle>
                                )}
                            </Route>
                            {/* 該公司客服紀錄清單 */}
                            <Route path={`${path}/customerSupports`}>
                                {customerSupports.length !== 0 ? (
                                    <CustomerSupportTable
                                        customerSupports={customerSupports}
                                        actions={[linkToCustomerSupport, linkToEditCustomerSupport]}
                                    />
                                ) : (
                                    <GridTitle>
                                        <EmptyMessage message="查無紀錄" />
                                    </GridTitle>
                                )}
                            </Route>
                        </Switch>
                    </Box>
                </>
            ) : (
                <Box>
                    <Grid>
                        <GridTitle>
                            <EmptyMessage message="查無資料" />
                        </GridTitle>
                    </Grid>
                </Box>
            )}
            {/* 返回上一頁的連結 */}
            <SmallCenterButtonGroup>
                <BackLink to={{ pathname: lastGoBackUrl, state: { goBackUrls: newGoBackUrls } }}>回公司一覽</BackLink>
            </SmallCenterButtonGroup>
        </PageArea>
    );
};

export default CompanyPage;
