import React from "react";
import { EditIconButton } from "../element/Button";
import { EmptyMessage } from "../element/Message";
import { Table, TableRow, TableCellHead, TableCell } from "../element/Table";
import { GridTitle } from "../element/Title";
import { COMPANY_STATUS_TAG } from "../constants/companyStatus";
import { COMPANY_VIP_TAG } from "../constants/companyVip";
import { PRODUCT_TEXT } from "constants/product";
import { SUBSCRIPTION_PERIOD_TEXT } from "constants/subscriptionPeriod";
import { toDate } from "../helper/dateTimeFormatter";
import "./Company.css";

/*
    顯示公司相關資料的外觀
*/

// 公司列表
export const CompanyTable = (props: { companies: any[]; actions?: any[] }) => {
    const { actions, companies } = props;
    return (
        <Table>
            <tbody>
                <TableRow>
                    <TableCellHead sortBy="status" pathFor="/company">
                        訂閱狀態
                    </TableCellHead>
                    <TableCellHead>公司名稱</TableCellHead>
                    <TableCellHead sortBy="taxIdNumber" pathFor="/company">
                        公司統編
                    </TableCellHead>
                    <TableCellHead>註冊帳號</TableCellHead>
                    <TableCellHead>訂閱產品</TableCellHead>
                    <TableCellHead>訂閱週期</TableCellHead>
                    <TableCellHead>註冊日期</TableCellHead>
                    <TableCellHead>最後登入</TableCellHead>
                    <TableCellHead>聯絡電話</TableCellHead>
                    {actions && <TableCellHead> </TableCellHead>}
                </TableRow>
                {companies.map((company: any, index) => {
                    const {
                        name,
                        status,
                        startDate,
                        lastLogin,
                        taxIdNumber,
                        futureManager: { email, phone },
                        subscriptions: [firstSubscription, ...restSubscriptions],
                    } = company;

                    return (
                        <TableRow key={index}>
                            <TableCell>{COMPANY_STATUS_TAG[status]} </TableCell>
                            <TableCell>{name}</TableCell>
                            <TableCell>{taxIdNumber}</TableCell>
                            <TableCell>{email}</TableCell>
                            <TableCell>
                                {firstSubscription && PRODUCT_TEXT[firstSubscription.product]}
                                {restSubscriptions.length !== 0 && "...其他"}
                            </TableCell>
                            <TableCell>
                                {firstSubscription && SUBSCRIPTION_PERIOD_TEXT[firstSubscription.period]}
                            </TableCell>
                            <TableCell>{toDate(startDate)}</TableCell>
                            <TableCell>{lastLogin ? toDate(lastLogin) : <EmptyMessage message="無紀錄" />}</TableCell>
                            <TableCell>{phone}</TableCell>
                            {actions && <TableCell>{actions && actions.map((action) => action(company))}</TableCell>}
                        </TableRow>
                    );
                })}
            </tbody>
        </Table>
    );
};

// 新註冊公司列表
export const CompanyNewRegisteredTable = (props: { companies: any[] }) => {
    const { companies } = props;
    return (
        <Table>
            <tbody>
                <TableRow>
                    <TableCellHead>公司名稱</TableCellHead>
                    <TableCellHead>公司統編</TableCellHead>
                    <TableCellHead>註冊帳號</TableCellHead>
                    <TableCellHead>註冊日期</TableCellHead>
                    <TableCellHead>最後登入</TableCellHead>
                    <TableCellHead>聯絡電話</TableCellHead>
                </TableRow>
                {companies.map((company: any, index) => {
                    const {
                        name,
                        taxIdNumber,
                        startDate,
                        lastLogin,
                        futureManager: { email, phone },
                    } = company;

                    return (
                        <TableRow key={index}>
                            <TableCell>{name}</TableCell>
                            <TableCell>{taxIdNumber}</TableCell>
                            <TableCell>{email}</TableCell>
                            <TableCell>{toDate(startDate)}</TableCell>
                            <TableCell>{lastLogin ? toDate(lastLogin) : <EmptyMessage message="無紀錄" />}</TableCell>
                            <TableCell>{phone}</TableCell>
                        </TableRow>
                    );
                })}
            </tbody>
        </Table>
    );
};

// 即將付款公司列表
export const CompanyWillPayTable = (props: { companies: any[] }) => {
    const { companies } = props;
    return (
        <Table>
            <tbody>
                <TableRow>
                    <TableCellHead>公司名稱</TableCellHead>
                    <TableCellHead>公司統編</TableCellHead>
                    <TableCellHead>付款狀態</TableCellHead>
                    <TableCellHead>付款方式</TableCellHead>
                    <TableCellHead>訂閱編號</TableCellHead>
                    <TableCellHead>付款金額</TableCellHead>
                    <TableCellHead>下個續訂日期</TableCellHead>
                </TableRow>
                {companies.map((company: any, index) => {
                    const {
                        name,
                        taxIdNumber,
                        subscriptions: [firstSubscription],
                    } = company;

                    return (
                        <TableRow key={index}>
                            <TableCell>{name}</TableCell>
                            <TableCell>{taxIdNumber}</TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell>
                                {firstSubscription ? firstSubscription.id : <EmptyMessage message="無紀錄" />}
                            </TableCell>
                            <TableCell> </TableCell>
                            <TableCell>
                                {firstSubscription ? (
                                    toDate(firstSubscription.nextPaymentDate)
                                ) : (
                                    <EmptyMessage message="無紀錄" />
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </tbody>
        </Table>
    );
};

// 註冊帳號詳細資訊
export const FutureManagerDetail = (props: { company: any }) => {
    const {
        company: {
            lastLogin,
            startDate,
            expiration,
            updatedAt,
            futureManager: { email },
        },
    } = props;
    return (
        <Table>
            <tbody>
                <TableRow>
                    <TableCellHead> </TableCellHead>
                    <TableCellHead>註冊帳號</TableCellHead>
                    <TableCellHead>註冊日期</TableCellHead>
                    <TableCellHead>最後登入</TableCellHead>
                    <TableCellHead>異動日期</TableCellHead>
                    <TableCellHead>中止日期</TableCellHead>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <EditIconButton title="編輯" disabled={true} />
                    </TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{toDate(startDate)}</TableCell>
                    <TableCell>{lastLogin ? toDate(lastLogin) : <EmptyMessage message="無" />}</TableCell>
                    <TableCell>{toDate(updatedAt)}</TableCell>
                    <TableCell>{expiration ? toDate(expiration) : <EmptyMessage message="無" />}</TableCell>
                </TableRow>
            </tbody>
        </Table>
    );
};

// 註冊公司詳細資訊
export const CompanyDetail = (props: { company: any }) => {
    const {
        company: {
            name: companyName,
            taxIdNumber,
            vip,
            futureManager: { name: futureManagerName, email, phone },
        },
    } = props;
    return (
        <>
            <GridTitle>公司資訊1</GridTitle>
            <Table>
                <tbody>
                    <TableRow>
                        <TableCellHead>公司統編</TableCellHead>
                        <TableCellHead>公司名稱</TableCellHead>
                        <TableCellHead>會員等級</TableCellHead>
                        <TableCellHead>公司網站</TableCellHead>
                        <TableCellHead>聯絡人</TableCellHead>
                        <TableCellHead>聯絡電話</TableCellHead>
                        <TableCellHead>傳真號碼</TableCellHead>
                        <TableCellHead>電子信箱</TableCellHead>
                    </TableRow>
                    <TableRow>
                        <TableCell>{taxIdNumber}</TableCell>
                        <TableCell>{companyName}</TableCell>
                        <TableCell>{COMPANY_VIP_TAG[vip]}</TableCell>
                        <TableCell> </TableCell>
                        <TableCell>{futureManagerName}</TableCell>
                        <TableCell>{phone}</TableCell>
                        <TableCell> </TableCell>
                        <TableCell>{email}</TableCell>
                    </TableRow>
                </tbody>
            </Table>
            <GridTitle>公司資訊2</GridTitle>
            <Table>
                <tbody>
                    <TableRow>
                        <TableCellHead>公司信箱</TableCellHead>
                        <TableCell> </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCellHead>登記地址</TableCellHead>
                        <TableCell> </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCellHead>通訊地址</TableCellHead>
                        <TableCell> </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCellHead>營業項目</TableCellHead>
                        <TableCell> </TableCell>
                    </TableRow>
                </tbody>
            </Table>
        </>
    );
};
