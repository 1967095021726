import React from "react";
import { Link, useHistory } from "react-router-dom";
import { PAGE_LIMIT_OPTIONS } from "../constants/defaultSetting";
import { paginationParams } from "../helper/paramsPathMaker";
import "./Panigate.css";

// 分頁連結元素
export const Paginate = (props: {
    count: number;
    limit: number;
    pathFor: string;
    currentPage: number;
    showInfo?: boolean;
    previouText?: string;
    nextText?: string;
    onPageChange?: Function;
    onLimitChange?: Function;
}) => {
    const history = useHistory();
    const {
        count,
        limit,
        pathFor,
        currentPage,
        showInfo = false,
        previouText = "<",
        nextText = ">",
        onPageChange,
        onLimitChange,
    } = props;
    const pageCount = Math.floor(count / limit) + (count % limit > 0 ? 1 : 0); // 算出總頁數
    const pageNumbers = Array.from({ length: pageCount }, (_, i) => i + 1); // 製出包含所有頁數數字的array
    const previousNumber = currentPage - 1; // 前一頁第幾頁
    const nextNumber = currentPage + 1; // 後一頁第幾頁
    return (
        <div className="field has-addons is-centered">
            {/* 選擇一頁顯示幾筆資料 */}
            <span className="paginate-limit">
                每頁
                <select
                    className="select is-small"
                    value={limit}
                    onChange={(event) => {
                        onLimitChange && onLimitChange();
                        history.push(pathFor + paginationParams(Number(event.target.value), 1));
                    }}
                >
                    {PAGE_LIMIT_OPTIONS.map((option, index) => {
                        return <option key={index}>{option}</option>;
                    })}
                </select>
                筆&nbsp;
            </span>
            {/* 前一頁連結 */}
            <p className="control">
                <Link
                    className="button is-small"
                    to={pathFor + paginationParams(limit, previousNumber > 0 ? previousNumber : 1)}
                    onClick={() => {
                        onPageChange && previousNumber > 0 && onPageChange(previousNumber);
                    }}
                >
                    {previouText}
                </Link>
            </p>
            {/* 每一頁連結 */}
            {pageNumbers.map((number, index) => {
                return (
                    <p className="control" key={index}>
                        <Link
                            className={`button is-small ${number === currentPage ? "is-link" : ""}`}
                            to={pathFor + paginationParams(limit, number)}
                            onClick={() => {
                                onPageChange && onPageChange(number);
                            }}
                        >
                            {number}
                        </Link>
                    </p>
                );
            })}
            {/* 後一頁連結 */}
            <p className="control">
                <Link
                    className="button is-small"
                    to={pathFor + paginationParams(limit, nextNumber > pageCount ? pageCount : nextNumber)}
                    onClick={() => {
                        onPageChange && nextNumber <= pageCount && onPageChange(nextNumber);
                    }}
                >
                    {nextText}
                </Link>
            </p>
            {/* 共幾頁目前第幾頁訊息 */}
            {showInfo && <span className="paginate-limit"> {`第${currentPage}/${pageCount}頁，共 ${count} 筆`} </span>}
        </div>
    );
};
