import React from "react";
import { Table, TableRow, TableCellHead, TableCell, TableCellEllipsis } from "../element/Table";
import { toDateTime } from "../helper/dateTimeFormatter";

/*
    顯示信件紀錄相關資料的外觀
*/

// 信件紀錄列表
export const SesRecordTable = (props: { sesRecords: any[]; actions?: any[] }) => {
    const { actions, sesRecords } = props;
    return (
        <Table>
            <tbody>
                <TableRow>
                    <TableCellHead>寄件信箱</TableCellHead>
                    <TableCellHead>收件信箱</TableCellHead>
                    <TableCellHead>寄送時間</TableCellHead>
                    <TableCellHead>寄送主機</TableCellHead>
                    <TableCellHead sortBy="subject" pathFor="/sesRecords">
                        標題
                    </TableCellHead>
                    <TableCellHead>內容</TableCellHead>
                    <TableCellHead sortBy="resendCount" pathFor="/sesRecords">
                        重送次數
                    </TableCellHead>
                    {actions && <TableCellHead> </TableCellHead>}
                </TableRow>
                {sesRecords.map((sesRecord: any, index) => {
                    const { from, to, lastSendTime, server, subject, content, resendCount } = sesRecord;
                    return (
                        <TableRow key={index}>
                            <TableCell>{from}</TableCell>
                            <TableCell>{to}</TableCell>
                            <TableCell>{toDateTime(lastSendTime)}</TableCell>
                            <TableCell>{server}</TableCell>
                            <TableCell>{subject}</TableCell>
                            <TableCellEllipsis>{content}</TableCellEllipsis>
                            <TableCell>{resendCount}</TableCell>
                            {actions && <TableCell>{actions && actions.map((action) => action(sesRecord))}</TableCell>}
                        </TableRow>
                    );
                })}
            </tbody>
        </Table>
    );
};
