/*
    客服紀錄的處理狀態設定
*/

import React from "react";

export const NOT_YET = 1;
export const IN_PROGRESS = 2;
export const PAUSE = 3;
export const FINISH = 9;

export const CUSTOMER_SUPPORT_STATUS_ARRAY = [NOT_YET, IN_PROGRESS, PAUSE, FINISH];

export const CUSTOMER_SUPPORT_STATUS_TEXT: { [index: number]: string } = {
    [NOT_YET]: "待處理",
    [IN_PROGRESS]: "處理中",
    [PAUSE]: "擱置",
    [FINISH]: "已結案",
};

// 直接使用寫好的component當回傳值方便統一維護
export const CUSTOMER_SUPPORT_STATUS_TAG: { [index: number]: any } = {
    [NOT_YET]: <span className="tag is-black">未處理</span>,
    [IN_PROGRESS]: <span className="tag is-warning">處理中</span>,
    [PAUSE]: <span className="tag is-light">擱置</span>,
    [FINISH]: <span className="tag is-success">已結案</span>,
};
