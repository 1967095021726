import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { toDateTime } from "../helper/dateTimeFormatter";
import { slashNToBr } from "../helper/htmlConvertter";
import "./CustomerSupportReply.css";

/*
    顯示客服回覆紀錄相關資料的外觀
*/

// 檢視客服回覆紀錄資訊
export const CustomerSupportReplyCard = (props: { customerSupportReply: any; actions?: any[] }) => {
    const {
        customerSupportReply: {
            reply,
            updatedAt,
            admin: { name: adminName },
        },
        actions,
    } = props;
    return (
        <div className="card">
            <header className="card-header">
                <p className="card-header-title">回覆</p>
            </header>
            <div className="card-content">
                <div className="content">
                    <div
                        className="customer-support-reply-content"
                        dangerouslySetInnerHTML={{ __html: slashNToBr(reply) }}
                    />
                </div>
                <div className="content">
                    <label className="customer-support-reply-label">
                        <FontAwesomeIcon icon={faUser} />
                        客服名稱
                    </label>
                    {adminName}
                    <label className="customer-support-reply-label">
                        <FontAwesomeIcon icon={faCalendar} />
                        最後更新時間
                    </label>
                    {toDateTime(updatedAt)}
                </div>
            </div>
            {actions && <footer className="card-footer">{actions.map((action) => action)}</footer>}
        </div>
    );
};

// 客服回覆紀錄表單
export const CustomerSupportReplyForm = (props: {
    customerSupportReply?: any;
    onSubmit?: Function;
    actions?: any[];
}) => {
    const {
        customerSupportReply = { reply: "" }, // 如果沒有從外部注入資料，則使用這邊設定的預設值作為表單的初始值
        onSubmit,
        actions,
    } = props;
    const [customerSupportReplyData, setCustomerSupportReplyData] = useState(customerSupportReply);
    const { reply } = customerSupportReplyData;
    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                const { id, reply } = customerSupportReplyData;
                onSubmit && onSubmit({ id, reply });
            }}
        >
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">回覆</p>
                </header>
                <div className="card-content">
                    <div className="content">
                        <div className="columns mb-0">
                            <div className="column">
                                <label className="label">回覆內容</label>
                                <div className="control">
                                    <textarea
                                        className="textarea"
                                        value={reply}
                                        required
                                        maxLength={1024}
                                        onChange={(event) => {
                                            setCustomerSupportReplyData({
                                                ...customerSupportReplyData,
                                                reply: event.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="columns mb-0">
                            <div className="column">
                                <div className="level">
                                    <div className="level-left"></div>
                                    <div className="level-right">
                                        <input
                                            className="button is-small"
                                            type="reset"
                                            value="重填"
                                            required
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setCustomerSupportReplyData(customerSupportReply);
                                            }}
                                        />
                                        <input className="button is-small is-success" type="submit" value="送出" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {actions && <footer className="card-footer">{actions.map((action: any) => action)}</footer>}
            </div>
        </form>
    );
};
