import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { getCustomerSupportsApi } from "../api/customerSupportApi";
import { PAGE_LIMIT, PAGE_LIMIT_OPTIONS } from "../constants/defaultSetting";
import { ERROR_RESPONSE_TEXT } from "../constants/errorResponseText";
import { OK, ERROR } from "../constants/responseStatus";
import { PageArea, Box } from "../element/Block";
import { ViewIconButton, EditIconButton } from "../element/Button";
import { SmallRightButtonGroup } from "../element/ButtonGroup";
import { EmptyMessage, ErrorMessage } from "../element/Message";
import { Paginate } from "../element/Paginate";
import { SearchBar } from "../element/Search";
import { PageTitle, GridTitle } from "../element/Title";
import { allQueryParams } from "../helper/paramsPathMaker";
import { CustomerSupportTable } from "../viewModel/CustomerSupport";

// 客服紀錄一覽頁面
const CustomerSupportsPage = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { search = "", sort = "", limit = PAGE_LIMIT, page = 1 } = useParams() as any; // 取出網址參數，若無則指定預設值
    const [searchText, setSearchText] = useState(search); // 搜尋條所輸入的關鍵字
    const [sortPair] = useState(sort); // 以哪個屬性順排或逆排
    const [currentLimit] = useState(PAGE_LIMIT_OPTIONS.includes(Number(limit)) ? Number(limit) : PAGE_LIMIT); // 每頁幾筆的參數，只能指定存在於PAGE_LIMIT_OPTIONS中的數值，否則變回預設值
    const [currentPage] = useState(Number(page)); // 目前第幾頁
    const [totalCount, setTotalCount] = useState(0); // 依目前的搜尋條件符合的客服紀錄總數
    const [customerSupports, setCustomerSupports] = useState([]); // 顯示在頁面上的客服紀錄資料
    const [error, setErrors] = useState("");
    // 設定一個API call用來取得符合搜尋條件的客服紀錄資料
    const getSupportsWithCallback = () => {
        getCustomerSupportsApi(searchText, sortPair, currentLimit, currentPage)
            .then((response) => {
                const {
                    data: { status, count, customerSupports: customerSupportsData, error },
                } = response;
                if (status === OK) {
                    setCustomerSupports(customerSupportsData); // 更新客服紀錄資料
                    setTotalCount(count); // 更新客服紀錄總數
                    setErrors(""); // 清空錯誤訊息
                } else if (status === ERROR) {
                    setErrors(error);
                }
            })
            .catch((error: any) => {
                const {
                    response: { status },
                } = error;
                setErrors(ERROR_RESPONSE_TEXT[status]);
            });
    };
    // 設定一個function用來產出連結到該客服紀錄的連結
    const linkToCustomerSupport = (customerSupport: any) => {
        const { id } = customerSupport;
        return (
            <Link
                to={{
                    pathname: `/customerSupport/id/${id}`, // 客服紀錄的連結
                    state: { goBackUrls: [pathname] },
                }}
                key="show"
            >
                <ViewIconButton key="show" title="檢視" />
            </Link>
        );
    };
    // 設定一個function用來產出連結到編輯該客服紀錄的連結
    const linkToEditCustomerSupport = (customerSupport: any) => {
        const { id, isActived } = customerSupport;
        return isActived ? (
            <Link
                to={{
                    pathname: `/customerSupport/id/${id}/edit`, // 編輯客服紀錄的連結
                    state: { goBackUrls: [pathname] },
                }}
                key="edit"
            >
                <EditIconButton key="edit" title="編輯" />
            </Link>
        ) : (
            <EditIconButton key="edit" title="編輯" disabled />
        );
    };
    useEffect(() => {
        getSupportsWithCallback();
    }, []);
    return (
        <PageArea>
            <PageTitle>客服紀錄一覽</PageTitle>
            {error && (
                <Box>
                    <ErrorMessage message={error} />
                </Box>
            )}
            <Box>
                <div className="level mb-3">
                    <div className="level-left">
                        <SearchBar
                            value={searchText}
                            onChange={(value: string) => {
                                setSearchText(value);
                            }}
                            onSearch={() => {
                                history.push("/customerSupport" + allQueryParams(searchText, sortPair));
                            }}
                        />
                    </div>
                    <div className="level-right">
                        <SmallRightButtonGroup>
                            <Link
                                className="button is-link "
                                to={{
                                    pathname: "/customerSupport/new",
                                    state: { goBackUrls: [pathname] },
                                }}
                            >
                                新增
                            </Link>
                        </SmallRightButtonGroup>
                    </div>
                </div>
                {totalCount > 0 ? (
                    <>
                        <CustomerSupportTable
                            customerSupports={customerSupports}
                            actions={[linkToCustomerSupport, linkToEditCustomerSupport]}
                        />
                        <Paginate
                            count={totalCount}
                            limit={currentLimit}
                            pathFor={"/customerSupport" + allQueryParams(searchText, sortPair)}
                            currentPage={currentPage}
                            showInfo={true}
                        />
                    </>
                ) : (
                    <GridTitle>
                        <EmptyMessage message="查無紀錄" />
                    </GridTitle>
                )}
            </Box>
        </PageArea>
    );
};

export default CustomerSupportsPage;
