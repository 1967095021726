import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { logout as logoutAction } from "../actions/loginAction";
import { logoutApi } from "../api/adminApi";
import { ADMIN_IDENTITY_TEXT } from "../constants/adminIdentity";
import { LogoutButton } from "../element/Button";

// 登入後畫面右上方的登入資訊
const LoginInfo = ({ currentAdmin, logoutAction }: any) => {
    const history = useHistory();
    // 取得登入admin的姓名跟身份類型
    const { name, identity } = currentAdmin;
    // 設定一個function呼叫登出API，成功後執行redux的logout action，以及導向到初始頁面
    const logoutWithCallback = () => {
        logoutApi().then(() => {
            logoutAction();
            history.push("/");
        });
    };
    return (
        <div className="navbar-end">
            <div className="navbar-item">
                {/* 顯示登入admin資訊 */}
                {name} <sub>{ADMIN_IDENTITY_TEXT[identity]}</sub>, 您好！
            </div>
            <div className="navbar-item">
                <div className="buttons">
                    {/* 設置logout按鈕，點下後執行logoutWithCallback */}
                    <LogoutButton value="登出" onClick={logoutWithCallback} />
                </div>
            </div>
        </div>
    );
};

// 將redux中的state的currentAdmin屬性取出，並回傳一個包含currentAdmin屬性的物件
const mapStateToProps = (state: any) => {
    return {
        currentAdmin: state.currentAdmin,
    };
};

// 把上方回傳物件中的所有屬性，綁定成LoginInfo的props，如此LoginInfo的props就會有個名為currentAdmin的值
// 以及綁定redux的logoutAction，如此LoginInfo的props就會有個名為logoutAction的function
const LoginInfoWithRedux = connect(mapStateToProps, {
    logoutAction,
})(LoginInfo);

// 輸出綁定過redux state跟action的LoginInfo
export default LoginInfoWithRedux;
