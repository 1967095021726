import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { login as loginAction, logout as logoutAction } from "./actions/loginAction";
import { getLoginedAdminApi } from "./api/adminApi";
import { OK, ERROR } from "./constants/responseStatus";
import { allQueryParams } from "./helper/paramsPathMaker";
import Header from "./layout/Header";
import CompaniesPage from "./pages/CompaniesPage";
import CompanyPage from "./pages/CompanyPage";
import CustomerSupportNewPage from "./pages/CustomerSupportNewPage";
import CustomerSupportPage from "./pages/CustomerSupportPage";
import CustomerSupportsPage from "./pages/CustomerSupportsPage";
// import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import ReportPage from "./pages/ReportPage";
import SesRecordsPage from "./pages/SesRecordsPage";
import "bulma/css/bulma.min.css";

// 整個react app的根節點component
function App(props: any) {
    const { currentAdmin, loginAction, logoutAction } = props;
    // 設定一個API call用來檢查每一次進入這個react app時是不是已經是登入的狀態
    const getLoginedAdminWithCallback = () => {
        getLoginedAdminApi()
            .then((response) => {
                const {
                    data: { status, user },
                } = response;
                if (status === OK) {
                    loginAction(user); // 成功的話用登入的admin資訊觸發redux的login action
                } else if (status === ERROR) {
                    logoutAction();
                }
            })
            .catch(() => {
                logoutAction();
            });
    };
    // 單純改變網址參數的情況無法讓route當中所指定的component重新render
    // 所以以網址參數改變不影響顯示的key屬性讓component重新render
    const paginationPage = (PageComponent: any) => {
        return (props: any) => {
            const {
                match: {
                    params: { search, sort, limit, page },
                },
            } = props;
            return <PageComponent key={allQueryParams(search, sort, limit, page)} />;
        };
    };
    // 回傳一個array，是pagePath跟所有可能的網址參數集合，讓route的path可以接受所有網址參數的組合
    const pathsAppendedQuery = (pagePath: string) => {
        return [
            pagePath + "/search/:search/sort/:sort/limit/:limit/page/:page",
            pagePath + "/search/:search/sort/:sort",
            pagePath + "/search/:search/limit/:limit/page/:page",
            pagePath + "/sort/:sort/limit/:limit/page/:page",
            pagePath + "/search/:search",
            pagePath + "/sort/:sort",
            pagePath + "/limit/:limit/page/:page",
            pagePath,
        ];
    };
    useEffect(() => {
        getLoginedAdminWithCallback();
    }, []);
    return (
        <div className="App">
            <Router>
                <Header />
                <Switch>
                    {!currentAdmin && <Route component={LoginPage} />}
                    <Route path="/company/id/:id" component={CompanyPage} />
                    <Route path={pathsAppendedQuery("/company")} render={paginationPage(CompaniesPage)} />
                    <Route path={pathsAppendedQuery("/sesRecords")} render={paginationPage(SesRecordsPage)} />
                    <Route path="/customerSupport/new" component={CustomerSupportNewPage} />
                    <Route path="/customerSupport/id/:id" component={CustomerSupportPage} />
                    <Route
                        path={pathsAppendedQuery("/customerSupport")}
                        render={paginationPage(CustomerSupportsPage)}
                    />
                    <Route path="/report" component={ReportPage} />
                    <Route path="/" component={CompaniesPage} />
                </Switch>
            </Router>
        </div>
    );
}

// 將redux中的state的currentAdmin屬性取出，並回傳一個包含currentAdmin屬性的物件
const mapStateToProps = (state: any) => {
    const { currentAdmin } = state;
    return {
        currentAdmin,
    };
};

// 把上方回傳物件中的所有屬性，綁定成App的props，如此App的props就會有個名為currentAdmin的值
// 以及綁定redux的loginAction跟logoutAction，如此App的props就會有名為loginAction跟logoutAction的function
const AppWithRedux = connect(mapStateToProps, {
    loginAction,
    logoutAction,
})(App);

// 輸出綁定過redux state跟action的App
export default AppWithRedux;
