import moment from "moment";

/*
    時間日期計算用helper
    存放時間日期計算或轉換function
*/

const onlyDate = "YYYY/MM/DD";
const onlyDateByDashed = "YYYY-MM-DD";
const dateAndTime = "YYYY/MM/DD HH:mm:ss";
const dateAndTimeByDashed = "YYYY-MM-DD HH:mm:ss";
const dateAndTimeLocal = "YYYY-MM-DDThh:mm";

// 將輸入日期時間轉換為純日期格式（斜線）
export const toDate = (dateTimeString: string | Date) => {
    return moment(dateTimeString).format(onlyDate);
};

// 將輸入日期時間轉換為純日期格式（橫線）
export const toDateByDashed = (dateTimeString: string | Date) => {
    return moment(dateTimeString).format(onlyDateByDashed);
};

// 將輸入日期時間轉換為日期時間格式（斜線）
export const toDateTime = (dateTimeString: string | Date) => {
    return moment(dateTimeString).format(dateAndTime);
};

// 將輸入日期時間轉換為日期時間格式（橫線）
export const toDateTimeByDashed = (dateTimeString: string | Date) => {
    return moment(dateTimeString).format(dateAndTimeByDashed);
};

// 將輸入日期時間轉換為日期時間格式（無秒數）
export const toDateTimeLocal = (dateTimeString: string | Date) => {
    return moment(dateTimeString).format(dateAndTimeLocal);
};

// 回傳輸入日期的昨天
export const toYesterday = (dateTimeString: string | Date) => {
    return moment(dateTimeString).add(-1, "days").format(dateAndTime);
};

// 回傳輸入日期的明天
export const toTomorrow = (dateTimeString: string | Date) => {
    return moment(dateTimeString).add(1, "days").format(dateAndTime);
};

// 回傳輸入日期的幾天前
export const toNDaysAgo = (dateTimeString: string | Date, days: number) => {
    return moment(dateTimeString).add(-days, "days").format(dateAndTime);
};

// 回傳輸入日期的幾天後
export const toNDaysLater = (dateTimeString: string | Date, days: number) => {
    return moment(dateTimeString).add(days, "days").format(dateAndTime);
};

// 起始時間點到結束時間點經過幾分鐘
export const minutesFromTo = (from: Date, to: Date) => {
    return moment(to).diff(from, "minutes");
};

// 起始時間點到結束時間點經過幾天
export const daysFromTo = (from: Date, to: Date) => {
    return moment(to).diff(from, "days");
};
